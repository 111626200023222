/*--sitemap style started here --*/

.sitemapwrapper {position:relative; padding:40px;}
.Sitemaphead h1 {font-size:29px; line-height:34px; text-align:center; margin: 30px auto 60px;  color:#354264; font-weight:700; font-family: 'Roboto', sans-serif;}
.Sitemaphead h1 span{position:relative;}
.sitemapcontent{position:relative; box-shadow:0 20px 30px rgb(56 56 56 / 25%); padding:40px;}
.sitemapcontent .grouplist{position:relative; margin-bottom:20px;}
.sitemapcontent h2 {font-size:20px; line-height:28px; margin-bottom:10px; padding-bottom:5px;color:#1a2a4e; font-family: 'Roboto', sans-serif; position:relative;font-weight:700;}
.sitemapcontent .grouplist h2 a{font-size:20px; line-height:28px; margin-bottom:05px;  padding-bottom:5px; color:#1a2a4e; font-weight:700; font-family: 'Roboto', sans-serif;}
.sitemapcontent .grouplist h2 a:hover {color:#6A738A; text-decoration:none;}
.sitemapcontent .grouplist ul {list-style:none; margin-left:0px; padding-left:0px; margin-bottom:0px;}
.sitemapcontent .grouplist ul li h3 {font-size:16px; line-height:20px; margin-bottom:5px;color:#414979; padding-bottom:5px; display:block; border-bottom:1px solid #6a738a2b; font-family: 'Roboto', sans-serif; font-weight:700!important;}
.sitemapcontent .grouplist ul li h3::after {background:none;}
.sitemapcontent .grouplist ul li {display:block; color:#6A738A;  position:relative; }
.sitemapcontent .grouplist ul li a {font-size:14px; color:#6A738A; text-decoration:none; font-family: 'Roboto', sans-serif;}
.sitemapcontent .grouplist ul li a:hover {color:#6A738A; text-decoration:none;}
.sitemapcontent .row {padding-bottom:10px;}
.primarylinks{font-weight:400;}