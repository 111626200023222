@tailwind base;
@tailwind components;
@tailwind utilities;

@import "header.css";
/* Using a string */
@import "landing.css";
@import "category.css";
@import "molecules.css";
@import "tools.css";
@import "home.css";
@import "sipbooster.css";
@import "cashFlow.css";
@import "movipack.css";
@import "pmsListing.css";
@import "tnc.css";
@import "kyc.css";
@import "pageDetailListing.css";
@import "mflist.css";
@import "compare.css";
@import "footer.css";
@import "faq.css";
@import "aboutUs.css";
@import "sitemap.css";
@import "mediaRoom.css";
@import "nomatch.css";
@import "whatsappPromoHeader.css";
/* @import "buyright.css";
@import "systematicinvestment.css"; */

:root {
  /* 'top' for hover menus and absolute elements */
  --top-for-menu-popup: 100px;
  --primaryBlue: #2B2E8C;
  --textBlue: #182A4E;
  --lightBlue: #6D798E;
  --mediumGrey: #454343;
  --accentBlue: #005DAC;
  --blueGrey: #42526E;
  --grey: #727272;
  --lightYellow: #FAAD19;
  --flatBlue: #F4F5F7;
  --borderColor: #D3D7DD;
  --borderGrey: #EBECF0;
  --titleWhite: #F0F0FF;
  --footerWhite: #FAFBFC;
  --buttonBorderGrey: #99A1B0;
  --darkGrey: #FFFFFF4D;
  --darkBlack: #191919;
}


body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

html .butler-font {
  font-family: 'Butler', sans-serif !important;
}

.img-responsive {
  max-width: 100%;
}

.box_shadow {
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
}

.bgWhite {
  background: #fff;
}

.padd0 {
  padding: 0px;
}

.slider_dots .slick-dots {
  bottom: 10px;
}

/* date picker atom */

.calender_position {
  position: relative;
}

.sy_Celender {
  position: absolute;
  z-index: 0;
  right: 20px;
  top: 10px;
}

.calender_position .react-datepicker-wrapper {
  width: 100%;
}

.Custfocus {
  cursor: pointer;
}

/* end date picker atom */

/* form margin */
.information-button {
  font-size: 14px;
  color: #fb4d4d;
  text-decoration: none;
}

.information-button:hover {
  text-decoration: none;
}

.nonBGtext .css-yk16xz-control,
.nonBGtext .txtbox {
  background: transparent;
}

.nobottomMrg {
  margin-bottom: 0px;
}

.formMargin {
  position: relative;
}

.noMrnoHe {
  margin-bottom: 0px;
  min-height: auto;
}

/* form margin end */
/* error */

.error-message {
  color: #ed1c24;
  font-size: 12px;
  position: absolute;
  text-align: center !important;
  display: contents;
}

.err_block {
  display: block;
  text-align: center !important;
  left: 0;
  right: 0;
  margin: 0px auto;
}

/* error */

/* label start */
.range-heading {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 15px;
  font-family: "Roboto";
  font-weight: 400;
  color: #9c9c9c;
  text-align: left;
}

.lbl {
  color: #6a738a;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
}

.input-lable {
  color: #adafb6 !important;
  font-size: 11px !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 0px !important;
  font-weight: 500;
}

i.pencil-iconsec {
  display: none;
}

@media (min-width: 200px) and (max-width: 767px) {
  .range-heading {
    text-align: center;
    margin-top: 25px;
    width: 100%;
  }

  .sliderCus .viewAllbtn {
    margin-top: 20px;
    font-size: 14px;
  }

  .swipe-section {
    float: left;
    margin-top: 20px;
  }

  .sliderCus .slick-next,
  .sliderCus .slick-prev {
    top: auto;
    bottom: 10px;
    -webkit-transform: translate(0, 0%);
    transform: translate(0, 0%);
    right: 20px !important;
    width: 50%;
    float: left;
  }

  .sliderCus .slick-prev {
    left: auto !important;
    right: 80px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .range-heading {
    margin-top: 25px;
    width: 100%;
  }
}

.red_text {
  color: #ff1111;
}

/* label end  */

/* input start */

.formRe {
  position: relative;
  width: 100%;
  min-height: 49px;
}

.txtbox {
  width: 100% !important;
  height: 38px !important;
  box-shadow: none;
  color: #21295a;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 0px;

  border-bottom: 1px solid #bfc3ce;
  width: 100%;
  padding: 5px 7px 7px 5px;
  outline: none;
}

.txtbox~.focus-border {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3399ff;
  transition: 0.4s;
}

.txtbox:focus~.focus-border {
  width: 100%;
  transition: 0.4s;
}

.iconPadd {
  padding-left: 40px;
}

.txtbox:hover,
.txtbox:focus {
  outline: none;

  box-shadow: none;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.nobgtxt {
  background-color: transparent;
}

.search_icon {
  width: 14px;
  height: 14px;
  display: block;
  background: url("../assets/images/searchicon.png") no-repeat center center;
  background-position: 100% 100%;
  background-size: contain;
  position: absolute;
  top: 14px;
  right: -6px;
}

.useIcon {
  background: url("../assets/images/contact/user.png") no-repeat center left;
}

.emailIcon {
  background: url("../assets/images/contact/mail.png") no-repeat center left;
}

.mobileIcon {
  background: url("../assets/images/contact/smartphone.png") no-repeat center left;
}

.cityIcon {
  background: url("../assets/images/contact/building_icon.png") no-repeat center left;
}

.useIcon,
.emailIcon,
.mobileIcon,
.cityIcon {
  text-indent: 24px;
}

.biginput {
  font-size: 32px;
  color: #21295a;
  font-family: "Roboto";
  font-weight: 700;
}

/* .txtBlock_input {
  display: block !important;
} */
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  input[type="text"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
}

/* input end */

/* Button start */

.invest_btn {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: all 1s;
  width: auto !important;
  min-width: auto !important;
  max-width: 100% !important;
}

/* focus/disabled styles, you can change this for accessibility */
.invest_btn:focus,
.invest_btn:disabled {
  outline: none;
  background: #aaa;
}

/* This is the space for the spinner to appear, applied to the button */
.spin {
  padding-right: 2.5em;
  display: block;
}

/* position of the spinner when it appears, you might have to change these values */
.spin .spinner {
  right: -0.6em;
  top: 0.4em;
  width: 2.5em;
  display: block;
  position: absolute;
}

/* spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* The actual spinner element is a pseudo-element */
.spin .spinner::before {
  content: "";
  width: 1.5em;
  /* Size of the spinner */
  height: 1.5em;
  /* Change as desired */
  position: absolute;
  top: 50%;
  right: 50%;
  border-radius: 50%;
  border: solid 0.35em #fff;
  /* Thickness/color of spinner track */
  border-bottom-color: #fb4d4d;
  /* Color of variant spinner piece */
  animation: 0.8s linear infinite spinner;
  /* speed of spinner */
  transform: translate(-50%, -50%);
  will-change: transform;
}

/* tools button */
.duration-btn {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  padding: 7px 25px;
  border-radius: 35px;
  float: left;
  margin-right: 10px;
  color: #6a738a;
  outline: none;
}

.activeBtn,
.duration-btn:hover {
  border: 1px solid #fb4d4d;
  color: #1a294e;
}

.activeBtnSecond,
.duration-btn:hover {
  border: 1px solid #fb4d4d;
  color: #1a294e;
}

.activeBtnCalc,
.duration-btn:hover {
  border: 1px solid #fb4d4d !important;
  color: #1a294e !important;
}

.compounding-Buttons {
  margin: 15px 0 35px;
  display: inline-flex;
}

.btn-radius {
  border-radius: 50px;
}

.blurbtn {
  box-shadow: 0 5px 20px #fb4d4d;
  min-width: auto;
  max-width: 100% !important;
  width: auto !important;
}

/* mf Button */

.btngroup {
  display: block;
  list-style: none;
  text-align: right;
  margin: 0px;
  padding: 0px;
}

.btngroup li {
  display: inline-table;
  width: 130px;
  margin-left: 10px;
}

.btngroup li:first-child {
  margin-left: 0px;
}

.whitebtn {
  background: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.btngroup .invest_btn {
  min-width: 130px !important;
}

.utbutton_left {
  text-align: left;
  /* margin-top: 50px; */
}

.btnRadius {
  border-radius: 40px !important;
}

.back_btn {
  color: #4e5b71;
  border: 1px solid #a7afbd;
  background-color: transparent;
}

.yg_submitBtn {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 700;
  background-color: #e63946;
  outline: none;
  border: 0px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  padding: 12px 30px;
}

.yg-btn {
  margin-top: 23px;
}

.outline_btn {
  color: #fb4d4d !important;
  background: transparent !important;
  margin-left: 10px;
  border: 1px solid #fb4d4d !important;
}

@media (min-width: 768px) and(max-width:992px) {
  .calculator-buttons .duration-btn {
    padding: 10px !important;
    font-size: 12px;
  }

  .calculator-buttons {
    margin-bottom: 20px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .duration-btn {
    padding: 5px 4px !important;
    font-size: 12px;
    margin-right: 4px !important;
  }

  .utbutton_left {
    text-align: center;
  }
}

/* Button End */

/* VIEW ALL */

.viewAllbtn {
  display: inline-block;
  color: #007bff;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: underline;
}

.viewall {
  text-transform: uppercase;
  border: 1px solid #eaecf1;
  /* background; */
  color: #84858b;
  padding: 7px 15px;
  font-size: 10px;
  text-decoration: none;
  border-radius: 4px;
}

.viewall:hover {
  text-decoration: none;
  color: #84858b;
}

/* VIEW ALL END */

/* atom - error.css start */

.error-message {
  color: #ed1c24;
  font-size: 12px;
  position: absolute;
  text-align: center !important;
  display: contents;
}

.err_block {
  display: block;
  text-align: center !important;
  left: 0;
  right: 0;
  margin: 0px auto;
}

/* atom - error.css end */

/* atom - formtitle css start */

.nonBGtext .css-yk16xz-control,
.nonBGtext .txtbox {
  background: transparent;
}

.nobottomMrg {
  margin-bottom: 0px;
}

.formMargin {
  position: relative;
  margin-bottom: 30px;
}

.noMrnoHe {
  margin-bottom: 0px;
  min-height: auto;
}

/* atom - formtitle css end */

/* atom - input.css start */

.formRe {
  position: relative;
  width: 100%;
}

.txtbox {
  width: 100% !important;
  height: 38px !important;
  box-shadow: none;
  color: #21295a;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 0px;

  border-bottom: 1px solid #bfc3ce;
  width: 100%;
  padding: 5px 7px 7px 5px;
  outline: none;
}

.txtbox~.focus-border {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3399ff;
  transition: 0.4s;
}

.txtbox:focus~.focus-border {
  width: 100%;
  transition: 0.4s;
}

.iconPadd {
  padding-left: 40px;
}

.txtbox:hover,
.txtbox:focus {
  outline: none;

  box-shadow: none;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.nobgtxt {
  background-color: transparent;
}

.search_icon {
  width: 14px;
  height: 14px;
  display: block;
  background: url("../assets/images/searchicon.png") no-repeat center center;
  background-position: 100% 100%;
  background-size: contain;
  position: absolute;
  top: 14px;
  right: -6px;
}

.useIcon {
  background: url("../assets/images/contact/user.png") no-repeat center left;
}

.emailIcon {
  background: url("../assets/images/contact/mail.png") no-repeat center left;
}

.mobileIcon {
  background: url("../assets/images/contact/smartphone.png") no-repeat center left;
}

.cityIcon {
  background: url("../assets/images/contact/building_icon.png") no-repeat center left;
}

.useIcon,
.emailIcon,
.mobileIcon,
.cityIcon {
  text-indent: 24px;
}

.biginput {
  font-size: 32px;
  color: #21295a;
  font-family: "Roboto";
  font-weight: 700;
}

/* .txtBlock_input {
  display: block !important;
} */
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  input[type="text"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
}

/* atom - input.css end */

/* atom - label.css start */

.range-heading {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 15px;
  font-family: "Roboto";
  font-weight: 400;
  color: #9c9c9c;
  text-align: left;
}

.lbl {
  color: #6a738a;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
}

.input-lable {
  color: #adafb6 !important;
  font-size: 11px !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 0px !important;
  font-weight: 500;
}

@media (min-width: 320px) and (max-width: 767px) {
  .range-heading {
    text-align: center;
    margin-top: 25px;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .range-heading {
    margin-top: 25px;
    width: 100%;
  }
}

/* form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #fb4d4d;
    padding: 9px;
    display: inline-block;
    position: relative;
    vertical-align: text-top;
    cursor: pointer;
    margin-right: 7px;
    border-radius: 3px;
    margin-top: 4px;
    position: absolute;
    left: 0;
} */

.red_text {
  color: #ff1111;
}

/* atom - label.css end */

/* atom - button.css start */

.invest_btn {
  background: #fb4d4d;
  font-size: 16px;
  border-radius: 0.25rem;
  border: 0px;
  color: #fff;
  font-family: "Roboto";
  font-weight: 700;
  text-transform: uppercase;
  padding: 9px 15px;
  width: 80%;
  max-width: 130px;
  width: 100%;
  margin-top: -10px;
  outline: none;
}

.invest_btn {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: all 1s;
  width: auto !important;
  min-width: auto !important;
  max-width: 100% !important;
}

/* focus/disabled styles, you can change this for accessibility */
.invest_btn:focus,
.invest_btn:disabled {
  outline: none;
  background: #aaa;
}

/* This is the space for the spinner to appear, applied to the button */
.spin {
  padding-right: 2.5em;
  display: block;
}

/* position of the spinner when it appears, you might have to change these values */
.spin .spinner {
  right: -0.6em;
  top: 0.4em;
  width: 2.5em;
  display: block;
  position: absolute;
}

/* spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* The actual spinner element is a pseudo-element */
.spin .spinner::before {
  content: "";
  width: 1.5em;
  /* Size of the spinner */
  height: 1.5em;
  /* Change as desired */
  position: absolute;
  top: 50%;
  right: 50%;
  border-radius: 50%;
  border: solid 0.35em #fff;
  /* Thickness/color of spinner track */
  border-bottom-color: #fb4d4d;
  /* Color of variant spinner piece */
  animation: 0.8s linear infinite spinner;
  /* speed of spinner */
  transform: translate(-50%, -50%);
  will-change: transform;
}

/* tools button */
.duration-btn {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  padding: 7px 25px;
  border-radius: 35px;
  float: left;
  margin-right: 10px;
  color: #6a738a;
  outline: none;
}

.activeBtn,
.duration-btn:hover {
  border: 1px solid #fb4d4d;
  color: #1a294e;
}

.activeBtnSecond,
.duration-btn:hover {
  border: 1px solid #fb4d4d;
  color: #1a294e;
}

.activeBtnCalc,
.duration-btn:hover {
  border: 1px solid #fb4d4d !important;
  color: #1a294e !important;
}

.compounding-Buttons {
  margin: 15px 0 35px;
  display: inline-flex;
}

.btn-radius {
  border-radius: 50px;
}

.blurbtn {
  box-shadow: 0 5px 20px #fb4d4d;
  min-width: auto;
  max-width: 100% !important;
  width: auto !important;
}

/* mf Button */

.btngroup {
  display: block;
  list-style: none;
  text-align: right;
  margin: 0px;
  padding: 0px;
}

.btngroup li {
  display: inline-table;
  width: 130px;
  margin-left: 10px;
}

.btngroup li:first-child {
  margin-left: 0px;
}

.whitebtn {
  background: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.btngroup .invest_btn {
  min-width: 130px !important;
}

.utbutton_left {
  text-align: left;
  /* margin-top: 50px; */
}

.btnRadius {
  border-radius: 40px !important;
}

.back_btn {
  color: #4e5b71;
  border: 1px solid #a7afbd;
  background-color: transparent;
}

.yg_submitBtn {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 700;
  background-color: #e63946;
  outline: none;
  border: 0px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  padding: 12px 30px;
}

.yg-btn {
  margin-top: 23px;
}

.outline_btn {
  color: #fb4d4d !important;
  background: transparent !important;
  margin-left: 10px;
  border: 1px solid #fb4d4d !important;
}

@media (min-width: 768px) and(max-width:992px) {
  .calculator-buttons .duration-btn {
    padding: 10px !important;
    font-size: 12px;
  }

  .calculator-buttons {
    margin-bottom: 20px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .duration-btn {
    padding: 5px 4px !important;
    font-size: 12px;
    margin-right: 4px !important;
  }

  .utbutton_left {
    text-align: center;
  }
}

/* atom - button.css end */

/* paragraph start */
.paraRegular {
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  color: #1a294e;
  margin-bottom: 30px;
  font-family: "Roboto";
  font-weight: 400;
}

.paraRegular p,
.paraRegular span {
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 16px !important;
}

.innerTab-info {
  font-size: 16px;
  line-height: 21px;
  color: #6a738a;
  font-family: "Roboto";
  font-weight: 400;
  margin: 5px 0 40px;
}

.date_grey {
  color: #9d9d9d;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  margin: 0px;
  padding-left: 20px;
}

.portfolio-information {
  font-size: 13px;
  color: #6a738a;
  line-height: 19px;
  margin: 10px 0;
  font-family: "Roboto";
  font-weight: 400;
}

.leadership-information {
  font-size: 16px;
  color: #6a738a;
  margin: 0 0 15px;
  line-height: 22px;
}

.padd0 {
  padding: 0px;
}

.mutiP {
  color: #c3c7d0 !important;
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 500;
}

.lessbottommrg {
  margin-bottom: 10px;
}

.sabHeading {
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 400;
  color: #1a294e;
  margin: 0px;
}

.sabHeading_lineHeigth {
  line-height: 25px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .paraRegular {
    font-size: 14px;
    line-height: 19px;
  }
}

/* paragraph end */

/* heading ATOM */
.headingH1 {
  color: var(--primaryBlue);
  font-size: 30px;
  line-height: 35px;
  position: relative;
  margin: 35px 0px;
  display: inline-block;
  font-family: Butler;
  font-weight: 700;
}

.logoTag span:after {
  width: 71px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  right: 0;
  bottom: -10px;

  background: url("../assets/images/about//headingImage.png") no-repeat;
}

.color_primary {
  color: var(--primaryBlue)
}

.color_white {
  color: #fff !important;
}

.logoTag:after {
  background: none;
  content: none;
}

.logoTag span,
.logoTag {
  position: relative;
}

.twoheading h2 {
  font-family: "Roboto";
  font-weight: 700;
  margin: 0px;
  color: #1a294e;
}

.innerTab-Heading {
  font-size: 24px;
  line-height: 32px;
  margin: 60px 0 0;
  color: #21295a;
  text-align: center;
  font-family: "Roboto";
  font-weight: 700;
}

.bold {
  font-family: "Roboto";
  font-weight: 700;
}

.key_head {
  color: #1a294e;
  font-size: 20px;
  margin: 0px;
  padding-left: 20px;
  font-family: "Roboto";
  font-weight: 700;
}

.size24 {
  font-size: 24px;
}

.paddLeft0 {
  padding-left: 0px;
}

.yg_loginTitle {
  color: #323b6d;
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 24px;
  margin: 30px 0px;
}

.title-noimg {
  display: inline-block;
  text-transform: uppercase;
  color: #27306b;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 700;
  vertical-align: middle;
  margin: 0 0;
}

.bannerHeading {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  color: #fff;
  margin: auto;
}

.color_white {
  color: #fff !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .headingH1 {
    font-size: 20px;
    line-height: 22px;
    margin-top: 9px;
  }

  .key_head {
    font-size: 17px;
  }

  .innerTab-Heading {
    margin-top: 20px;
    font-size: 20px;
    line-height: 22px;
  }
}

/* heading ATOM */

/* image ATOM */
.full_imge {
  width: 100%;
}

.img-center {
  margin: auto;
  display: block;
}

.imgabs_right {
  position: absolute;
  right: 10%;
  top: 20%;
}

@media (min-width: 768px) and (max-width: 992px) {
  .imgabs_right {
    position: absolute;
    right: 1%;
    top: 20%;
  }
}

/* end Image ATOM */
.toolsColor {
  background-image: linear-gradient(to right, #21295a, #4d568b);
  padding: 15px 15px 10px;
}

.light-Greenbg {
  background-color: rgba(244, 246, 251, 0.5);
}

/* section start */

.bg_color_full {
  background: #f4f6fb;
  padding: 30px 0px;
}

.secPadd50 {
  padding: 50px 0px;
}

.whiteBG {
  background: #fff;
}

.passiveBottm {
  min-height: 630px;
  background: url("../assets/images/landing/passBGbottom.svg") #f4f6fb no-repeat;
  background-position: left;
  background-position: 7% 100%;
}

.Avatar {
  min-height: 630px;

  background: url("../assets/images/landing/hourse.svg") #f4f6fb no-repeat;
  background-position: left;
  background-position: -70% -50%;
}

.pmstree {
  background: url("../assets/images/pms/PMS-Tree.svg") #f4f6fb no-repeat;
  background-position: left;
}

.listingtitle {
  /* height: 300px; */
  padding-top: 0px;
}

.key-Gradient {
  min-height: 245px;
  height: auto;
  background-image: linear-gradient(to right, #21295a, #4d568b);
  z-index: 1;
  margin: 40px 0 5px;
}

.relative {
  position: relative;
}

.hiddensec {
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 992px) {
  .Avatar {
    background-position: center;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .passiveBottm {
    min-height: auto;
  }
}

/* end section */

/* loader start */
.body_loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.75);
}

.section_loader {
  width: 100%;
  min-height: 200px;
  position: relative;
  overflow: hidden;
}

.loading_div {
  position: absolute;
  width: 90px;
  height: 90px;
  /* border: 1px solid red; */
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  display: block;
  bottom: 0;
  /* background: url('../.../assets/images/Loader.gif') no-repeat center center;
*/
}

/* TABNAV  atom*/
.leftNavtab {
  padding: 10px 30px;
  margin-top: -30px;
  padding-top: 50px;
}

.leftNavUl {
  margin: 0px;
  padding: 0px;
  width: 228px;
  padding-left: 60px;
  padding-right: 16px;
}

.leftNavUl li {
  display: block;
  color: #c3c7d0;
  font-size: 18px;
  line-height: 23px;
  cursor: pointer;
  word-break: break-word;
  position: relative;
  font-family: "Roboto";
  font-weight: 400;
  margin-bottom: 20px;
}

.leftNavUl li.activeleftNav {
  color: var(--primaryBlue);
  font-weight: 500;
}

.leftNavUl li.activeleftNav::after {
  position: absolute;
  content: "";
  left: -50px;
  width: 40px;
  top: 50%;
  border: 1px solid var(--primaryBlue);
}

/* innerNav */

.innerNav {
  width: auto;
  /* height: 70px; */
  overflow: auto;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  padding: 0px;
  transition: all 0.3s ease;
  background: #fff;
  clear: both;
  box-shadow: 4px 5px 20px rgba(0, 0, 0, 0.3);
  margin: 0px auto;
  margin-top: -38px;
  border-radius: 4px;
}

.innerNav li {
  display: inline-block;
  cursor: pointer;
}

.innerNav li a,
.innerNav li a:hover {
  font-size: 16px;
  text-align: center;
  padding: 20px 25px !important;
  text-decoration: none;
  color: #6a738a;
  transition: all 0.3s ease;
  display: block;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  font-family: "Roboto";
  font-weight: 400;
}

.innerNav li a.active {
  text-decoration: none;
  color: var(--primaryBlue);
  border-color: var(--accentBlue)
}

/* tab nav left */

.minAccState,
.bgacc {
  min-height: 600px;
  padding-top: 30px;
  /* background:#fff; */
}

.bodycolor_faq {
  background-color: #f4f6fb;
}

.minAccState {
  background: #fff;
}

.bodycolor_faq .bgacc .formMargin .css-1s2u09g-control {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #bfc3ce;
  border-radius: 0;
}

.heigth100vh {
  min-height: 100vh;
}

.awareness-sec .DownloadData {
  display: none;
}

.guidesec {
  font-size: 14px;
}

.list-roman,
.sublist-roman {
  list-style: upper-roman;
}

.sublist-loweralpha {
  list-style: lower-alpha;
}

ol.list-roman li::marker {
  font-size: 1.25rem;
  font-weight: 700;
}

.paralink {
  color: #3c434f;
}

.paralink:hover {
  color: #3c434f;
  text-decoration: none;
}

@media (min-width: 768px) and (max-width: 992px) {
  .leftNavUl {
    width: auto;
    padding-left: 15px;
    padding-right: 0px;
  }

  .innerNav {
    width: 100%;
    margin-top: -10px;
    height: auto;
  }

  .heigth100vh {
    min-height: auto;
  }

  .minAccState,
  .bgacc {
    min-height: auto;
  }
}

@media (min-width: 320px) and (max-width: 767px) {

  .minAccState,
  .heigth100vh {
    min-height: auto;
  }

  .minAccState,
  .bgacc {
    padding-top: 10px;
  }

  .innerNav {
    width: 100%;
    margin-top: -10px;

    height: auto;
  }

  .innerNav li a,
  .innerNav li a:hover {
    padding: 15px 15px;
  }

  /* .innerNav li a.active {
        background: #FEC007;
        color: #fff;
        border-radius: 4px;
        box-shadow: 0px 3px 6px #000;
    } */
}

/* TABNAV atom END */

/* end loader end */
/*================= pages start================== */

/* internationl business  */

.colBlock {
  border: 1px solid #eeeeee;
  max-width: 782px;
  display: block;
  padding: 10px 40px;
  box-sizing: border-box;
  margin: 10px auto;
}

.col_Img {
  width: 150px;
  position: relative;
  display: block;
  float: left;
  height: 138px;
  margin-top: -25px;
}

.col_Img img {
  max-width: 100%;
}

.imgLayerBorder {
  width: 136px;
  height: 139px;
  border: 1px solid #ffc107;
  position: absolute;
  z-index: 99;
  top: -15px;
  right: -2px;
}

.colImgDesc {
  color: #6a738a;
  font-size: 18px;
  width: 77%;
  padding-left: 10px;
  box-sizing: border-box;
  padding-top: 10px;
  display: block;
  float: left;
  font-family: "Roboto";
  font-weight: 400;
}

.interTesti {
  width: 100%;
  margin: 30px 0px;
  padding: 55px 0px;
  background: #1a294e;
}

.testino_inter {
  font-size: 26px;
  line-height: 38px;
  font-family: "Roboto";
  font-weight: 700;
  color: #fff;
  margin: 0px;
  z-index: 90;
  text-indent: 26px;
  position: relative;
  text-align: center;
}

.testino_inter span {
  display: block;
}

.testino_inter::before {
  content: "";
  width: 30px;
  height: 30px;
  background: url("../assets/images/international/tag.png") no-repeat;
  background-size: cover;
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: -6px;
  z-index: 10;
  left: 9px;
}

.keyChar {
  list-style: none;
  margin: 0px;
  text-align: center;
  padding: 0px;
}

.keyChar li {
  padding: 10px 20px;
  width: 24%;
  display: inline-table;
}

.keyChar li img {
  max-width: 181px;
  width: auto;
  height: auto;
}

.keyChar li p {
  color: #415484;
  font-size: 21px;
  margin-top: 15px;
  line-height: 23px;
  font-family: "Roboto";
  font-weight: 400;
}

.divider_n {
  border: 2px solid #6a738a;
  width: 60px;
  margin: 25px auto;
  display: block;
}

.websiteINfo {
  color: #1a294e;
  font-size: 18px;
  margin: 0px;
  font-family: "Roboto";
  font-weight: 400;
}

.websiteINfo span {
  font-family: "Roboto";
  font-weight: 700;
  display: block;
  margin-top: 6px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .colBlock {
    padding: 10px 10px;
  }

  .col_Img {
    float: none;
    margin: 10px auto;
  }

  .colImgDesc {
    width: 100%;
  }

  .testino_inter {
    font-size: 20px;
  }
}

/* international  business*/

/* atom - tab.css start */
.no_bg_before:before {
  content: none;
}

.bgacc {
  background-color: transparent;
  min-height: 560px;
  position: relative;
  padding-left: 30px;
}

.bgAccount {
  background-color: #f4f6fb;
}

.bgAccount:before {
  background-image: url("../assets/images/account/accountBG.svg");
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  opacity: 0.1;
  background-position: right bottom;
  background-size: 40%;
  z-index: 0;
  bottom: 0;
  right: 0;
}

/* atom - tab.css end */

/* atom - dob.css start */

.datePickerReact {
  width: 100%;
}

.calender_position {
  position: relative;
}

.sy_Celender {
  position: absolute;
  z-index: 20;
  right: 20px;
  top: 10px;
}

.calender_position .react-datepicker-wrapper {
  width: 100%;
}

.Custfocus {
  cursor: pointer;
  padding: 7px 4px;
}

/* atom - dob.css end */

/* atom - reactSelect.css start */

.txtSelect .css-1okebmr-indicatorSeparator {
  display: none;
}

.txtSelect .css-g1d714-ValueContainer {
  padding-left: 0px;
  color: #323b6d;
  font-family: "Roboto";
  font-weight: 400;
  height: 38px;
  font-size: 14px;
}

.txtSelect .css-2b097c-container {
  outline: none;
}

.txtSelect.css-1pahdxg-control:hover {
  border-color: transparent !important;
}

.txtSelect .css-1pahdxg-control {
  outline: none !important;
  border: 0px;
  border-color: transparent;
}

.txtSelect .css-1n7v3ny-option,
.txtSelect .css-yt9ioa-option {
  color: #323b6d;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
}

.txtSelect .css-9gakcf-option {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}

.txtSelect .css-26l3qy-menu {
  margin-top: 2px;
  z-index: 99;
}

.txtSelect .css-tlfecz-indicatorContainer {
  color: #1a294e;
}

.txtSelect .css-yk16xz-control,
.txtSelect .css-yk16xz-control:focus {
  border-radius: 0px;
  border: 0px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #323b6d;
  border-radius: 0px;
  outline: none;
  border-bottom: 1px solid #bfc3ce;
}

/* .css-yk16xz-control { height: 38px;} */
.filter-status .formMargin {
  margin-bottom: 0;
  min-height: 100%;
}

.filter-status .css-yk16xz-control,
.txtSelect .css-yk16xz-control:focus {
  height: 25px;
  min-height: 25px;
}

.filter-status .txtSelect .css-g1d714-ValueContainer {
  height: 22px;
  font-size: 12px;
}

.filter-status .css-1hb7zxy-IndicatorsContainer {
  -webkit-align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.filter-status .css-tlfecz-indicatorContainer {
  padding: 0 8px;
}

.txtSelect .css-26l3qy-menu,
.txtSelect .css-26l3qy-menu:focus {
  font-size: 12px;
}

/* .css-1uccc91-singleValue {
    color: #323b6d !important
} */

/* normal select start */
.txtbox {
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: none;
  border-bottom: 1px solid #bfc3ce;
  font-size: 14px;
  color: #323b6d;
  font-family: "Roboto";
  font-weight: 400;
}

select.txtbox {
  padding-right: 25px;
  -webkit-appearance: menulist;
  border-radius: 0px;
}

.txtbox:hover,
.txtbox:focus {
  outline: none;
  box-shadow: none;
}

.bigtxt .css-1uccc91-singleValue {
  font-size: 25px;
  color: #09327f;
}

.bigtxt .css-yk16xz-control {
  min-height: auto !important;
  height: auto !important;
}

/* normal select start */

.textCapitalize {
  text-transform: capitalize;
}

.txtSelect .css-b8ldur-Input input {
  width: 100% !important;
}

.txtSelect .css-1g6gooi input {
  width: 100% !important;
}

.css-4ljt47-MenuList {
  overflow-y: scroll !important;
}

/* atom - reactSelect.css end */

/* atom - pagination.css start */

.pagination {
  padding: 0px 5px;
}

/* Pagination css */

.pagination-table .page-item.active .page-link {
  background-color: #25375b;
  border-color: #25375b;
}

.pagination-table .page-link {
  color: #25375b;
}

.pagination-table .page-link:hover {
  color: #162138;
}

.pagination-table .page-item.active .page-link:hover {
  color: #fff;
}

.pagination-table .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 55, 91, 0.25);
}

/* atom - pagination.css end */

/* about us  */
.sliderceo {
  width: 85%;
  margin: 20px auto;
  display: block;
  /* min-height: 500px; */
  padding: 63px 0px 40px;
  overflow-x: hidden;
}

.secondSlide {
  width: 85%;
  margin: 20px auto;
  padding: 0px 60px 30px;
}

.sliderceo img {
  max-width: 100%;
}

.sliderceo .slick-slide {
  padding: 0px 5px;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.sliderceo .slick-list {
  overflow: initial !important;
}

.sliderceo .slick-slider {
  /* overflow: none !important;
    z-index: 2; */
  position: relative;
  z-index: 0;
  -ms-overflow-x: hidden;
}

.sliderceo .slick-center {
  transform: scale(1);
  outline: none;
  border: 0px;
  z-index: 88;
  position: relative;
}

.sliderceo .slick-slider .overlayBlack {
  background: rgba(0, 0, 0, 0.54);
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  /* z-index: 3; */
}

.sliderceo .slick-center .overlayBlack {
  display: none;
}

.sliderceo .slick-current {
  opacity: 100 !important;
}

.sliderceo .slick-center:hover {
  outline: none;
}

.yg_peopleSilder .slick-prev,
.yg_peopleSilder .slick-next {
  top: 0;
}

.sliderceo {
  width: 85%;
  margin: 20px auto;
  display: block;
  /* min-height: 500px; */
  padding: 63px 0px 40px;
  overflow-x: hidden;
}

.secondSlide {
  width: 85%;
  margin: 20px auto;
  padding: 0px 60px 30px;
}

.sliderceo img {
  max-width: 100%;
}

.sliderceo .slick-slide {
  padding: 0px 5px;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.sliderceo .slick-list {
  overflow: initial !important;
}

.sliderceo .slick-slider {
  /* overflow: none !important;
    z-index: 2; */
  position: relative;
  z-index: 0;
  -ms-overflow-x: hidden;
}

.sliderceo .slick-center {
  transform: scale(1);
  outline: none;
  border: 0px;
  z-index: 88;
  position: relative;
}

.sliderceo .slick-slider .overlayBlack {
  background: rgba(0, 0, 0, 0.54);
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  /* z-index: 3; */
}

.sliderceo .slick-center .overlayBlack {
  display: none;
}

.sliderceo .slick-current {
  opacity: 100 !important;
}

.sliderceo .slick-center:hover {
  outline: none;
}

.yg_peopleSilder .slick-prev,
.yg_peopleSilder .slick-next {
  top: 0;
}

.profileImg {
  width: 200px;
  height: 220px;
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ceoName {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 400;
  color: #444;
}

.ceoPosition {
  font-size: 14px;
}

.ceoDetail {
  text-align: center;
  padding: 0px 10px;
  font-size: 14px;
  color: #1a294e;
  height: 250px;
  overflow-y: auto;
}

.descBOx {
  margin-top: 0px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .aboutCounter {
    margin: 0 auto;
  }

  .aboutCounter p {
    font-size: 30px;
  }

  .aboutCounter span {
    font-size: 14px;
  }

  .buildImg {
    min-height: 300px;
  }

  .leftNavtab {
    padding: 0;
    margin-top: 0;
  }

  .leftNavUl {
    padding-left: 0;
    padding-right: 0;
  }

  .bgacc {
    padding-top: 0;
  }

  .profileImg {
    margin: 0 auto;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .ceoDetail {
    padding: 0px 30px;
  }

  .secondSlide,
  .sliderceo {
    width: 100%;
    padding: 0px;
  }

  .sliderceo .slick-center {
    transform: scale(1);
  }

  .aboutCounter {
    overflow: auto;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
  }

  .buildImg {
    min-height: auto;
  }

  .trustImg {
    max-width: 100%;
    width: 100%;
    left: 0;
    right: 0;
    position: relative;
    margin: auto;
    margin-bottom: 15px;
  }

  .aboutbig {
    width: 100%;
    position: relative;
    bottom: 0;
  }

  .aboutCounter li {
    width: 218px;
    min-height: 183px;
    background-color: #ededed;
    margin-left: 10px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 4px;
    background: url("../assets/images/about/abtres.png") #ededed;
    background-size: cover;
  }

  .aboutCounter span {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    color: #fff;
    display: block;
    padding: 10px 10px;
    min-height: 70px;
  }

  .aboutCounter p {
    color: #444;
    font-weight: bold;
  }

  .bginvet2 {
    width: 100%;
    min-height: 300px;
  }

  .fourTRingle {
    overflow: auto;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
  }

  .fourTRingle li {
    width: 300px;
    display: inline-table;
    /*  min-height: 272px; */
  }

  .capNameLetter {
    font-size: 65px;
    height: 150px;
  }

  .bannersitfull {
    padding-top: 15px;
  }

  .pdffile img {
    position: relative;
    top: 0;
    margin-top: 10px;
  }

  .twoheading h2 {
    font-size: 20px;
  }

  .fourTRingle li p {
    white-space: normal;
  }

  .profileImg {
    margin: 0 auto;
  }
}

.sliderCus .slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
  background-size: contain !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: none !important;
  content: none !important;
}

.sliderCus .slick-next {
  background: url("../assets/images/arrowleft.png") no-repeat;
}

.sliderCus .slick-next:hover::before,
.sliderCus .slick-next:focus,
.sliderCus .slick-next:hover {
  background: url("../assets/images/arrowleft.png") no-repeat !important;
}

.sliderCus .slick-prev {
  transform: rotate(180deg);
  background: url("../assets/images/arrowleft.png") no-repeat;
}

.sliderCus .slick-prev:hover::before,
.sliderCus .slick-prev:focus,
.sliderCus .slick-prev:hover {
  transform: rotate(180deg);
  background: url("../assets/images/arrowleft.png") no-repeat !important;
}

/* end abut us */

/* atom - banner.css start */
.relative {
  position: relative;
}

.bannerHeading {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  color: #fff;
  margin: auto;
}

/* atom - banner.css end */

/* table css start */
.table_beckMarks tr th,
.table_beckMarks tr td {
  padding: 10px 20px !important;
  font-size: 14px;
  color: #1a294e;
  font-family: "Roboto";
  font-weight: 500;
  border-top: 0px !important;
}

.table_beckMarks tr th {
  background: #323b6d;
  color: #fff;
}

.table_beckMarks {
  margin-bottom: 15px;
}

.table_beckMarks .table {
  margin-bottom: 0px;
}

.table_beckMarks tbody tr:hover {
  background: #fffae9;
}

/* table css ends */

/*atom dob/dob.js starts*/
.datePickerReact {
  width: 100%;
}

.calender_position {
  position: relative;
}

.sy_Celender {
  position: absolute;
  z-index: 20;
  right: 20px;
  top: 10px;
}

.calender_position .react-datepicker-wrapper {
  width: 100%;
}

.Custfocus {
  cursor: pointer;
  padding: 7px 4px;
}

/*atom dob/dob.js ends*/

/* Slider CSS START */
/* range slider */

.rangeSliderContainer {
  width: 100%;
}

.rangeSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 4px;
  background: #e4e4f2;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-bottom: 10px;
  padding: 0px;
  border-bottom: 0px;
}

.rangeSlider:hover {
  background-color: #cccccc;
}

/* IE specific styles */
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .rangeSlider {
    background: transparent;
    height: 20px;
  }

  .rangeSlider:hover {
    background-color: transparent;
  }
}

/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #6a6988 !important;
  cursor: pointer;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #6a6988 !important;
  cursor: pointer;
  border: 1px solid #ffffff;
}

/* All the same stuff for IE */
/* input[type=range]::-ms-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #6A6988 !important;
    cursor: pointer;
    border: 1px solid #FFFFFF;
} */
input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #e4e4f2 !important;
  border-radius: 10px;
  height: 5px;
}

input[type="range"]::-ms-fill-upper {
  background: #e4e4f2 !important;
  border-radius: 10px;
  height: 5px;
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #6a6988 !important;
  cursor: pointer;
  border: 1px solid #ffffff;
  z-index: 1;
}

/* input[type="range"]:focus::-ms-fill-lower {
    background: #6a6988;
}
input[type="range"]:focus::-ms-fill-upper {
    background: #6a6988;
} */

.min-amount {
  font-size: 11px;
  color: #bfbfbf;
  line-height: 11px;
  text-align: left;
  margin: 0;
  float: left;
}

.max-amount {
  font-size: 11px;
  color: #bfbfbf;
  line-height: 11px;
  text-align: right;
  margin: 0;
  float: right;
}

/* Slider CSS ENDS */

/* Toggle checkbox CSS STARTS */

.can-toggle {
  position: relative;
  z-index: 0;
}

.can-toggle *,
.can-toggle *:before,
.can-toggle *:after {
  box-sizing: border-box;
}

.can-toggle input[type="checkbox"] {
  /* opacity: 0;
  position: absolute;
  top: 0;
  left: 0; */
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.can-toggle input[type="checkbox"][disabled]~label {
  pointer-events: none;
}

.can-toggle input[type="checkbox"][disabled]~label .can-toggle__switch {
  opacity: 0.4;
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch:after {
  content: attr(data-checked);
}

.can-toggle label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.can-toggle label .can-toggle__label-text {
  -webkit-box-flex: 1;
  flex: 1;
  padding-left: 32px;
}

.can-toggle label .can-toggle__switch {
  position: relative;
}

.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}

.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: white;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.blur_color label .can-toggle__switch:after {
  background: #323b6d;
  color: #fff;
}

.blur_color.can-toggle--size-large label .can-toggle__switch:before {
  color: #6a738a !important;
}

.blur_color input[type="checkbox"]:checked~label .can-toggle__switch:after {
  color: #fff !important;
}

.blur_color.can-toggle--size-large label .can-toggle__switch:after {
  color: #fff !important;
}

.blur_color label .can-toggle__switch {
  border-color: #e2e3e5 !important;
}

.can-toggle input[type="checkbox"][disabled]~label {
  color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type="checkbox"]:focus~label .can-toggle__switch,
.can-toggle input[type="checkbox"]:hover~label .can-toggle__switch {
  /* background-color: #777; */
  background-color: transparent;
}

.can-toggle input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover~label .can-toggle__switch:after {
  color: #5e5e5e;
}

.can-toggle input[type="checkbox"]:hover~label {
  color: #6a6a6a;
}

.can-toggle input[type="checkbox"]:checked~label:hover {
  color: #1a294e;
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch {
  /* background-color: #70c767; */
  background-color: transparent;
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch:after {
  color: #1a294e;
}

.can-toggle input[type="checkbox"]:checked:focus~label .can-toggle__switch,
.can-toggle input[type="checkbox"]:checked:hover~label .can-toggle__switch {
  /* background-color: #5fc054; */
  background-color: transparent;
}

.can-toggle input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
  color: #1a294e;
}

.can-toggle label .can-toggle__label-text {
  -webkit-box-flex: 1;
  flex: 1;
}

.can-toggle label .can-toggle__switch {
  -webkit-transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  /* background: #848484; */
  background: transparent;
  border: 1px solid #fff;
}

.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}

.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1),
    -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #777;
}

.can-toggle input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover~label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch:after {
  -webkit-transform: translate3d(65px, 0, 0);
  transform: translate3d(65px, 0, 0);
}

.can-toggle input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle label {
  font-size: 14px;
}

.can-toggle label .can-toggle__switch {
  height: 36px;
  -webkit-box-flex: 0;
  flex: 0 0 134px;
  border-radius: 4px;
}

.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
}

.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
}

.can-toggle label .can-toggle__switch:hover:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-small input[type="checkbox"]:hover~label .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small input[type="checkbox"]:checked~label .can-toggle__switch:after {
  -webkit-transform: translate3d(44px, 0, 0);
  transform: translate3d(44px, 0, 0);
}

.can-toggle.can-toggle--size-small input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-small input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small label {
  font-size: 13px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch {
  height: 28px;
  -webkit-box-flex: 0;
  flex: 0 0 90px;
  border-radius: 2px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
  left: 45px;
  font-size: 10px;
  line-height: 28px;
  width: 45px;
  padding: 0 12px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
  top: 1px;
  left: 1px;
  border-radius: 1px;
  width: 44px;
  line-height: 26px;
  font-size: 10px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-large input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-large input[type="checkbox"]:hover~label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-large input[type="checkbox"]:checked~label .can-toggle__switch:after {
  -webkit-transform: translate3d(98%, 0, 0);
  transform: translate3d(98%, 0, 0);
}

.can-toggle.can-toggle--size-large input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-large input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-large label {
  font-size: 14px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch {
  height: 32px;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  border-radius: 18px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch:before {
  left: 50%;
  font-size: 12px;
  line-height: 32px;
  width: 50%;
  padding: 0 12px;
  color: #fff;
  font-family: "Roboto";
  font-weight: 400;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch:after {
  top: 1px;
  left: 2px;
  border-radius: 18px;
  width: 50%;
  line-height: 28px;
  font-size: 12px;
  color: #1a294e;
  font-family: "Roboto";
  font-weight: 400;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch:hover:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.demo-rebrand-1 input[type="checkbox"][disabled]~label {
  color: rgba(181, 62, 116, 0.5);
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-1 input[type="checkbox"]:hover~label .can-toggle__switch {
  background-color: #b53e74;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-1 input[type="checkbox"]:hover~label .can-toggle__switch:after {
  color: #8f315c;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:hover~label {
  color: #a23768;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked~label:hover {
  color: #39916a;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked~label .can-toggle__switch {
  background-color: #44ae7f;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked~label .can-toggle__switch:after {
  color: #368a65;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover~label .can-toggle__switch {
  background-color: #3d9c72;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
  color: #2f7757;
}

.can-toggle.demo-rebrand-1 label .can-toggle__label-text {
  -webkit-box-flex: 1;
  flex: 1;
}

.can-toggle.demo-rebrand-1 label .can-toggle__switch {
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  background: #c14b81;
}

.can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.6);
}

.can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  color: #b53e74;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"][disabled]~label {
  color: rgba(68, 68, 68, 0.5);
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-2 input[type="checkbox"]:hover~label .can-toggle__switch {
  background-color: #444;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-2 input[type="checkbox"]:hover~label .can-toggle__switch:after {
  color: #2b2b2b;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:hover~label {
  color: #373737;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked~label:hover {
  color: #62b125;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked~label .can-toggle__switch {
  background-color: #75d32d;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked~label .can-toggle__switch:after {
  color: #5da924;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:hover~label .can-toggle__switch {
  background-color: #69be28;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
  color: #52941f;
}

.can-toggle.demo-rebrand-2 label .can-toggle__label-text {
  -webkit-box-flex: 1;
  flex: 1;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  -webkit-transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  background: #515151;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.7);
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  color: #444;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-2 input[type="checkbox"]:hover~label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked~label .can-toggle__switch:after {
  -webkit-transform: translate3d(58px, 0, 0);
  transform: translate3d(58px, 0, 0);
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.demo-rebrand-2 label {
  font-size: 13px;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  height: 60px;
  -webkit-box-flex: 0;
  flex: 0 0 120px;
  border-radius: 60px;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  left: 60px;
  font-size: 13px;
  line-height: 60px;
  width: 60px;
  padding: 0 12px;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 30px;
  width: 58px;
  line-height: 56px;
  font-size: 13px;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:hover:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

/* investmentform */
.toggleCheck {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 14px;
}

.toggleCheck input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleCheck .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: -5px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100px;
}

.toggleCheck .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: #b2b2b2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggleCheck input:checked+.slider {
  background-color: #e8e8e8;
}

.toggleCheck input:focus+.slider {
  box-shadow: 0 0 1px #e8e8e8;
}

.toggleCheck input:checked+.slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */
.toggleCheck.slider.round {
  border-radius: 34px;
}

.toggleCheck .slider.round:before {
  border-radius: 50%;
}

.radio {
  margin: 10px 0;
  display: inline-block;
}

.radio-label {
  padding-left: 0;
  color: #21295a;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
}

.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio input[type="radio"]+.radio-label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #fb4d4d;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: 0em;
  left: -3px;
  margin-right: 5px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.radio input[type="radio"]:checked+.radio-label:before {
  background-color: #fb4d4d;
  box-shadow: inset 0 0 0 3.5px #fff;
}

.radio input[type="radio"]:focus+.radio-label:before {
  outline: none;
  border-color: #fb4d4d;
}

.radio input[type="radio"]:disabled+.radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #fb4d4d;
  background: #fff;
}

.radio input[type="radio"]+.radio-label:empty:before {
  margin-right: 0;
}

.radio_pay {}

/* Toggle checkbox CSS ENDS */

/* checkbox  CSS STARTS */
.checkBoxLabel {
  padding: 0 0 20px 0px;
}

.checkBoxLabel input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkBoxLabel label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  color: #6a738a;
  font-weight: none;
}

.checkBoxLabel label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #fb4d4d;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
  padding: 9px;
  display: inline-block;
  /* position: relative; */
  vertical-align: middle;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
}

.checkBoxLabel input:checked+label:after {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  top: 4px !important;
  left: 36px !important;
  width: 6px !important;
  height: 14px !important;
  border: solid #fb4d4d !important;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg) !important;
}

/* checkbox  CSS ENDS */

.showing_txt {
  text-align: right;
  font-size: 15px;
  color: #1a294e;
  padding-right: 30px;
  font-family: "Roboto";
  font-weight: 400;
}

.txtSelect .css-1g6gooi input {
  margin-bottom: 0px;
}

/* Add this renumeration css */
.renumeration-sec h4 {
  color: #1a294e;
  font-size: 30px;
  line-height: 35px;
  margin: 0;
  font-weight: 700;
  font-family: "Roboto";
  margin-bottom: 15px;
}

.renumeration-sec h5 {
  font-size: 16px;
  color: #1a294e;
  margin: 0;
  font-family: "Roboto";
  font-weight: 700;
  margin: 15px 0;
}

.renumeration-sec .table_pass tr th {
  background: #272e59;
  padding: 15px 15px !important;
  color: #fff !important;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 13px;
  font-weight: 400;
}

.renumeration-sec .table_pass tr td {
  color: #1a294e;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  white-space: nowrap;
  padding: 15px 15px !important;
}

.react-read-more-read-less-mor,
.readmoreLess {
  color: #323b6d;
  font-family: "Roboto";
  display: inline-block;
}

.profit_tri {
  color: #61b550 !important;
}

.excelDOWNload {
  width: 40px;
  height: 40px;
  font-size: 15px;

  display: inline-table;
  background: url("../assets/images/movipack/xls.png") no-repeat center center;
  border-radius: 7px;
  vertical-align: bottom;
  margin-right: 15px;
}



/* ===================== Common Classes for Rebranding ======================== */

.primary-blue-button {
  border: none;
  font-size: 14px;
  padding: 0.5rem 1.35rem;
  background-color: #2B2E8C;
  color: #FFF;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

.primary-blue-button:hover {
  background-color: #373CD3;
  color: #FFF
}

.secondary-white-button {
  border: 1px solid #D3D7DD;
  background-color: #FFF;
  color: #42526E;
  font-weight: 600;
  ;
  cursor: pointer;
  font-size: 14px;
  padding: 0.5rem 1.35rem;
  border-radius: 8px;
  text-align: center;
}

.secondary-white-button:hover {
  background-color: #F4F5F7;
  color: #42526E
}


html .section-heading-text {
  font-family: Butler !important;
  color: var(--primaryBlue) !important;
  font-size: 40px;
  font-weight: 700;
  line-height: 46.4px;
  letter-spacing: -0.01em;
}

html .section-subHeading-text {
  color: var(--primaryBlue) !important;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 35.84px;
}


@media (max-width: 480px) {
  html .section-heading-text {
    font-size: 24px;
    line-height: 30px;
  }

  html .section-subHeading-text {
    font-size: 18px;
    line-height: 24px;
  }
}