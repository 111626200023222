/* .no-match-container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.no-match {
  text-align: center;
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  padding: 0;
  color: #61dafb;
  width: 350px;
  height: 50px;
} */

.no-match-container {
  background-color: #F4F6FB;
  min-height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}
.no-match h4{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 35px;
  color: #FF0000;
}
.no-match p{
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}
.btn-red {
  background-color: var(--primaryBlue);
  color: #fff;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 700;
  padding: 12px 18px;
  letter-spacing: 1px;
  font-weight: 600;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: block;
  text-align: center;
  min-width: 200px;
  margin: 20px auto;
  border-radius: 0.25rem;
}

@media (min-width: 320px) and (max-width: 767px){
  .no-match h4{
    font-size: 32px;
  }
  .no-match p{
    padding: 0px 20px;
    font-size: 14px;
  }
  .textBreak{
    display: none;
  }
}