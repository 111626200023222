@font-face {
  font-family: "DINCond-Bold";
  src: url("../fonts/DINCond-Bold.eot");
  src: url("../fonts/DINCond-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/DINCond-Bold.woff2") format("woff2"), url("../fonts/DINCond-Bold.woff") format("woff"), url("../fonts/DINCond-Bold.ttf") format("truetype"), url("../fonts/DINCond-Bold.svg#DINCond-Bold") format("svg");
}

@font-face {
  font-family: "DINCond-Regular";
  src: url("../fonts/DINCond-Regular.eot");
  src: url("../fonts/DINCond-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/DINCond-Regular.woff2") format("woff2"), url("../fonts/DINCond-Regular.woff") format("woff"), url("../fonts/DINCond-Regular.ttf") format("truetype"), url("../fonts/DINCond-Regular.svg#DINCond-Regular") format("svg");
}

/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/roboto-v20-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'), url('../fonts/roboto-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-100.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'), url('../fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'), url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/roboto-v20-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'), url('../fonts/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

body {
  font-family: 'Roboto';
font-weight: 400;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #3c434f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
}

.padd0 {
  padding: 0px !important;
}

@media (min-width: 320px) and(max-width:767px) {
  .mobile-center {
    text-align: center;
    display: block;
  }
}
