.faq_search {
    max-width: 554px;
    min-height: 60px;
    margin: auto;
    margin-bottom: 40px;
    position: relative;
}

.faqtxt {
    width: 77.5%;
    height: 50px;
    border: 1px solid #c3c7d0;
    background: #fff;
    border-radius: 30px;
    padding: 10px 15px;
    font-size: 16px;
    color: #6a738a;
    display: block;
    float: left;
    font-family: 'Roboto';
font-weight: 400;
}

.faqtxt:focus{
    outline: none;
}

.faqSearchbtn {
    width: 18.5%;
    height: 60px;
    display: block;
    border-radius: 30px;
    border: 0px;
    box-shadow: none;
    margin-left: 4%;
    float: left;
    background: url("../assets/images/faq/searchbar.svg") var(--primaryBlue) no-repeat center center;
}

.faq-filter {
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.faq-filter li {
    display: inline-table;
    border: 1px solid #c3c7d0;
    border-radius: 23px;
    color: #6a738a;
    background: #fff;
    font-family: 'Roboto';
font-weight: 400;
    font-size: 16px;
    padding: 10px 30px;
    cursor: pointer;
    margin-right: 15px;
}

.faq-filter li:last-child {
    margin-right: 0px;
}

.faq-filter li.active {
    background: #232b5a;
    color: #fff;
}

@media(min-width:768px) and (max-width:992px) {
    .faq-filter{
        overflow: auto;
        white-space: nowrap;
        min-height: 60px;
    }
}

@media(min-width:320px) and (max-width:767px) {
    .faq-filter{
        overflow: auto;
        white-space: nowrap;
        min-height: 60px;
    }
}