/*SIP booster section style here */
body {
  font-family: "Roboto", sans-serif;
}
body p {
  font-family: "Roboto", sans-serif;
}
.banner-sec {
  background-color: #e4eafe;
  /*height:90%;*/
  padding: 90px 0 40px;
}
.btn-red {
  background-color: #e63946;
  color: #fff;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 700;
  font-weight: 600;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: block;
  text-align: center;
  width: auto;
  border-radius: 0.25rem;
  padding: 10px 20px;
}
.btn-red:hover {
  background-color: var(--accentBlue);
  color: #fff;
  text-decoration: none;
  box-shadow: 0 5px 10px 0 rgb(238 52 57 / 40%);
}
#banner1 .banner1 {
  position: relative;
  border-radius: 4px;
  padding: 15px 10px;
  width: 328px;
  height: auto;
  display: block;
}
#banner1 .tringle {
  width: 0px;
  height: 0px;
  background-color: red; /* border-top: 87px solid gray; */
  border-right: 0px solid blue;
  border-bottom: 88px solid white;
  border-left: 1340px solid #fef200;
  position: absolute;
  bottom: -88px;
  z-index: 0;
}
.borderline {
  width: 1352px;
  height: 20px;
  position: absolute;
  transform: rotate(-4deg);
  bottom: -49px;
  left: -2px;
  z-index: 0;
  background: #fcf9b9;
}
#banner1 .banner-sec h2 {
  padding-right: 116px;
}
#banner1 h1 {
  color: #25385b;
  font-size: 35px;
  line-height: 40px;
  text-align: left;
  font-weight: 700;
}
#banner1 h1 span {
  color: #ec2028;
  font-size: 35px;
  line-height: 40px;
  text-align: left;
  font-weight: 700;
}
#banner1 h3 {
  color: #354264;
  font-size: 20px;
  line-height: 26px;
  text-align: left;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 15px;
}
#banner1 p {
  color: #354264;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 15px;
  max-width: 500px;
}
#banner1 .form-group .btn-red {
  margin: 0px 0px 10px;
}
#banner1 .bannergraphics {
  position: absolute;
  bottom: -40px;
  right: 0;
  z-index: 999;
}
.content_banner {
  position: relative;
  z-index: 9;
}
.bottomgra {
  position: absolute;
  bottom: -110px;
  left: 0;
  width: 100%;
  z-index: 999;
}
.tringleimg {
  position: absolute;
  bottom: -110px;
  left: 0;
  width: 100%;
  z-index: 998;
}
.banner-sec {
  position: relative;
}
.bottomborder {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9;
}
.bottomborder img {
  width: 100%;
  height: auto;
  display: block;
  max-width: 1366px;
  height: 147px;
}

/*--popup--*/
.user_options-forms .forms_title,
.forms_title {
  margin-bottom: 60px;
  font-size: 1rem;
  line-height: 1em;
  text-transform: uppercase;
  color: #202121;
  letter-spacing: 0.05rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.form-group label.fixed-lable {
  font-size: 11px;
  line-height: normal;
  color: #999999;
  position: relative;
}
.login-box input.form-control {
  display: block;
  width: 100%;
  padding: 10px 7px;
  font-family: "Roboto", sans-serif;
  height: auto;
  background-clip: padding-box;
  font-size: 14px;
  line-height: normal;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#panerror {
  color: red;
  float: left;
  margin-top: 0px;
  padding: 5px 7px 10px;
  display: block;
}
.fail-pan {
  font-size: 10px;
  padding-left: 2px;
  padding-top: 5px;
  margin-bottom: 0;
}

.red-btn:hover,
.red-btn:active,
.red-btn:focus {
  background-color: #ee3439;
  /* box-shadow: 0 5px 30px rgb(251 173 28 / 30%), 0 5px 20px rgb(251 173 28 / 5%); */
  -webkit-box-shadow: 0 5px 15px 0 rgb(238 52 57 / 80%);
  box-shadow: 0 5px 15px 0 rgb(238 52 57 / 80%);
  color: #fff;
}

#dvAuthorize {
  position: relative;
}

.banner-sec h2 {
  font-size: 34px;
  color: #f3e5bd;
  margin-bottom: 10px;
  position: relative;
}
#bbenefits {
  padding-top: 60px !important;
  position: relative;
}
.boostercontent {
  position: relative;
}
.boostercontent h3 {
  font-size: 29px;
  line-height: 80px;
  color: #354264;
  text-align: left;
  font-weight: 700;
  margin-top: 114px;
  text-transform: capitalize;
}
.featurebx {
  position: relative;
  padding: 30px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  margin: 0 32px 32px 0;
  max-width: 300px;
}
.featureicon {
  margin-bottom: 10px;
}
.featurebx h5 {
  font-size: 16px;
  font-weight: 600;
  color: #6a738a;
}
.featurebx p {
  margin-bottom: 0px;
}
.boosterimage {
  position: relative;
}
.bullicon {
  top: 100px;
  width: 100%;
  max-width: 570px;
  display: block;
  position: relative;
}
.bullshadow {
  position: absolute;
  bottom: 0px;
}

.howitworks {
  position: relative;
 
  padding: 120px 0;
  background-color: #282F4B;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%231f274c' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23161f4d' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%230d174d' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23040d4d' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%2301004B' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%231a014b' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%232a004b' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%2337004a' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%2341004a' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%234B0249' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
}
.howitworks h3 {
  font-size: 29px;
  line-height: 80px;
  color: #ffffff;
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
}
.howitworks p {
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}
.listtype {
  position: absolute;
  left: 0px;
}
.howtoworktable {
  position: relative;
}
.howtoworktable td {
  font-size: 14px;
  line-height: 19px;
  color: #6a738a;
}
.howtoworktable .head1 {
  color: #fff;
  background: #f18021;
  font-size: 16px;
  text-align: center;
}
.howtoworktable .head2 {
  color: #fff;
  background: #3d3b3a;
  font-size: 14px;
  text-align: center;
  padding: 19px;
}
.tg-nrix {
  padding: 5px;
}
.howtoworktable {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
}
.howtoworktable td {
  border-left: 1px solid #ededed;
  background: #ffffff;
  padding: 5px;
  border-bottom: 1px solid #ededed;
}
.howtoworktable th {
  border-left: 1px solid #ededed;
}
.howtoworktable th:nth-child(1) {
  border-left: 0px;
}

.infographics {
  position: relative;
  padding: 60px 0;
}
#benefites-sipbooster h3, .infographics h3, .why-gsec h3.titleone {
  font-size: 29px;
  line-height: 35px;
  color: #354264;
  text-align: center;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.why-gsec h3.titleone{text-align: left;}
.topintro {
  position: relative;
  text-align: center;
}
.topintro p {
  margin-bottom: 10px;
  color: #707070;
  font-size: 14px;
  line-height: 19px;
}

.maininfographic {
  position: relative;
  margin: 30px 0;
  margin-top: 110px;
}
.comparewidget {
  position: relative;
  float: left;
}
.comparewidget.first {
  position: relative;
}
.comparewidget.first .calenderwrap::before {
  background: none;
}
.comparewidget.first .calenderwrap::after {
  background: none;
}
.comparewidget.first .coinwrap {
  height: 100px;
}
.comparewidget ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  float: left;
  clear: both;
}
.comparewidget ul li {
  display: inline-block;
  float: left;
}
.coinwrap {
  position: relative;
  width: 286px;
  background: #efefef;
  text-align: center;
  min-height: 100%;
  height: 150px;
}
.midwrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.coinwrap img {
  position: relative;
  width: 64px;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}
.coinwrap p {
  text-align: center;
  margin-bottom: 10px;
}
.calenderwrap {
  position: relative;
  width: 158px;
  padding: 22px 0;
  background: #ffffff;
  text-align: center;
}
.calenderwrap img {
  z-index: 2;
  position: relative;
}
.calenderwrap p {
  font-size: 20px;
  color: #707070;
  margin-bottom: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -23px;
  z-index: 3;
}
.calenderwrap::after {
  content: "";
  width: 73px;
  height: 17px;
  position: absolute;
  top: 50%;
  right: 0;
  background: url(../assets/images/sipbooster/rightarrow.svg) no-repeat center
    center;
  z-index: 1;
}
.calenderwrap::before {
  content: "";
  width: 73px;
  height: 17px;
  position: absolute;
  top: 50%;
  left: 0;
  background: url(../assets/images/sipbooster/leftarrow.svg) no-repeat center
    center;
  z-index: 1;
}
.gapcontainer {
  position: relative;
  margin: 15px 0;
  float: left;
  width: 100%;
}
.breakingyear img {
  display: block;
  margin: 0 auto;
}
.breakingyear span {
  position: absolute;
  bottom: 0px;
  left: 50%;
  margin-left: -45px;
}
.profilewrap {
  width: 150px;
  position: absolute;
  display: block;
  background: white;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.growthofinv {
  position: relative;
  padding: 0px 0 20px;
}
.colorpallet {
  position: relative;
}
.howtoworktable td.head2:nth-child(1) {
  border-left: 0px;
}

/*--style for graph --*/
.base {
  position: absolute;
  bottom: 0;
  left: -35px;
  width: 100%;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  -moz-transform: skew(45deg);
  -o-transform: skew(45deg);
  -webkit-transform: skew(45deg);
}
.chart-container {
  position: relative;
  z-index: 0;
  margin: 10px auto 0;
  width: 200px;
  height: 200px; /*border: solid 1px #bbb;*/
  background-color: rgba(
    255,
    255,
    255,
    0.055
  ); /*  -webkit-box-shadow: 0 8px 6px -6px #444;
*/
}
.chart-container:after {
  content: "";
  position: absolute;
  top: -15px;
  left: -8px;
  height: 15px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.055);
  -moz-transform: skew(45deg);
  -o-transform: skew(45deg);
  -webkit-transform: skew(45deg);
  transform: skew(45deg);
}
.chart-container > table {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
  width: 100%;
  height: 100%;
}
.chart-container table tr td {
  width: 100px;
  height: 98x;
  border: solid 1px rgba(255, 255, 255, 0.05);
}
.devider {
  position: absolute;
  right: 0px;
  top: 0px;
}
span.iconrupee {
  width: 7px;
  position: relative;
  display: inline-block;
  /* float: left; */
  top: 5px;
  margin-right: 5px;
}
.iconrupee img {
  max-width: 100%;
}
.midwrap p em {
  font-style: normal;
  display: inline-block;
  /* float: left; */
}
.rupeeicon {
  position: relative;
  top: 4px;
}

/* --------------------- Vertical Bars ----------------------*/
.bar {
  float: left;
  position: absolute;
  bottom: 0;
  z-index: 99;
  height: 35%;
  width: 40px;
  margin: 0 40px;
  background-color: rgba(107, 255, 49, 0.35);
}
.bar:before {
  content: "";
  position: absolute;
  left: -15px;
  bottom: 7px;
  height: 100%;
  width: 15px;
  background-color: rgba(107, 255, 49, 0.25);
  -webkit-transform: skewY(45deg);
}
.bar:after {
  content: "";
  position: absolute;
  top: -15px;
  left: -8px;
  width: 40px;
  height: 15px;
  background-color: rgba(107, 255, 49, 0.45);
  -moz-transform: skewX(45deg);
  -o-transform: skewX(45deg);
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
}
.two {
  left: 36.5%;
  height: 57%;
}
.three {
  left: 36.5%;
  height: 57%;
}
.four {
  left: 74.5%;
  height: 78%;
}
.bar.one {
  background: #f39544;
}
.bar.one::before {
  background: #f27711;
}
.bar.one::after {
  background: #f3a666;
}
.bar.two {
  background: #2d3981;
}
.bar.two::before {
  background: #21295a;
}
.bar.two::after {
  background: #4d5fce;
}
.bar.three {
  background: #f39544;
}
.bar.three::before {
  background: #f27711;
}
.bar.three::after {
  background: #f3a666;
}
.bar.four {
  background: #2d3981;
}
.bar.four::before {
  background: #21295a;
}
.bar.four::after {
  background: #4d5fce;
}
.colcolorpallet {
  position: relative;
}
.colcolorpallet h3 {
  color: #354264;
  text-align: center;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.colcolorpallet ul {
  position: relative;
  margin: 0px;
  padding: 0px;
  list-style: none;
  float: right;
}
.colcolorpallet ul li {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}
.colcolorpallet ul li span {
  width: 32px;
  height: 21px;
  margin-right: 10px;
  display: inline-block;
}
.colcolorpallet ul li span.orange {
  background: #f39544;
}
.colcolorpallet ul li span.blue {
  background: #2d3981;
}
.colcolorpallet ul li b {
  position: relative;
  display: inline-block;
  top: -5px;
}
.icount {
  position: absolute;
  top: -40px;
  left: -23px;
  width: 180px;
}
.notegraph p {
  font-size: 15px;
  color: #707070;
  text-align: center;
}
.notegraph {
  position: relative;
  width: 100%;
  display: block;
  padding: 20px 0;
}
.icount span.iconrupee {
  width: 7px;
  position: relative;
  display: inline-block;
  /* float: left; */
  top: 5px;
  margin-right: 5px;
}
.icount em {
  font-style: normal;
  display: inline-block;
  /* float: left; */
}
#benefites-sipbooster {margin-top: 50px;}
.unit {
  display: flex;
  flex: 0 1 100%;
  margin-bottom: -10px;
  margin-left: -10px;
}

.unit-spacing-md {
  margin-bottom: -20px;
  margin-left: -25px;
}
.unit-spacing-md > * {
  margin-bottom: 20px;
  margin-left: 25px;
}
.box-icon-classic-count {
  font-size: 60px;
  font-weight: 600;
  color: rgba(21, 21, 21, 0.2);
  line-height: 1;
}
.box-icon-classic-title {
  margin-top: 4px;
}
p.box-icon-classic-text {
  font-size: 14px;
}
.unit-left,
.unit-right {
  flex: 0 0 auto;
  max-width: 100%;
}
.unit-body {
  flex: 0 1 auto;
}

.box-icon-classic-title {
  font-weight: 600;
  font-family: "Roboto", serif;
  text-transform: uppercase;
  font-size: 18px;
  color: #151515;
}
p.box-icon-classic-text {
  font-size: 14px;
}

#loginformpopup .modal-body {
  padding: 15px 20px;
}
#loginformpopup .modal-content:before {
  content: "";
  background: rgba(197, 217, 255, 0.3);
  width: 100%;
  height: 170px;
  border-radius: 6px 6px 100% 100%;
  box-shadow: 0 5px 10px rgb(197 217 255 / 70%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/* new css by amit */
img { max-width: 100%; height: auto; vertical-align: top; border: 0 none;outline: 0;}

.howitworks h3 {font-size: 29px; line-height: 80px; color: #FFFFFF; text-align: left; font-weight: 700; text-transform: capitalize;}

h3:after { width: 71px; height: 16px; display: block; content: ""; position: absolute; right: 0; bottom: -20px; background: url(/assets/imgages/AMC/underline.svg) no-repeat;}

span.iconrupee {width: 7px; position: relative;display: inline-block; 
  /* float: left;  */
  vertical-align: middle;
  top: 5px;margin-right: 5px;}

span.iconrupee {top: 7px;}

/*responsive for booster page */
@media screen and (min-width: 1200px) {
  .tringleimg {
    bottom: -100px;
  }
  .bottomgra {
    bottom: -100px;
  }
}

@media screen and (min-width: 1024px) {
  .tringleimg {
    bottom: -100px;
  }
  .bottomgra {
    bottom: -95px;
  }
  #banner1 .bannergraphics {
    bottom: -72px;
  }
  .featurebx {
    max-width: 43%;
  }
  .bullshadow {
    bottom: 30px;
    width: 100%;
  }
  #bbenefits {
    padding-bottom: 40px;
  }
  .howitworks {
    padding: 40px 0;
  }
  .infographics {
    padding: 40px 0;
  }
  .coinwrap {
    width: 210px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 992px) {
  .banner-sec {
    padding: 40px 0;
  }
  #banner1 h1 {
    font-size: 29px;
  }
  #banner1 h1 span  {
    font-size: 29px;
  }
  #banner1 h3 {
    font-size: 20px;
  }
  .tringleimg {
    bottom: -98px;
  }
  .bottomgra {
    bottom: -98px;
  }
  .featurebx {
    max-width: 44%;
  }
  .bullshadow {
    bottom: 40px;
    width: 80%;
  }
  .coinwrap {
    width: 220px;
  }
  .profilewrap {
    width: 130px;
    height: 130px;
  }
  .infographics h3 {
    line-height: 35px;
  }
  .devider {
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: auto;
  }
}

@media screen and (min-width: 1024px) {
  .devider {
    display: block;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .banner-sec {
    padding: 40px 0;
  }
  #banner1 .bannergraphics {
    bottom: -40px;
    width: 60%;
  }
  #banner1 h1 {
    font-size: 22px;
    line-height: 26px;
  }
  #banner1 h1 span {
    font-size: 22px;
    line-height: 26px;
  }
  #banner1 h3 {
    font-size: 18px;
  }
  .tringleimg {
    bottom: -76px;
  }
  .bottomgra {
    bottom: -76px;
  }
  .featurebx {
    max-width: 47%;
  }
  .featurebx.even {
    margin-right: 0px;
  }
  .bullshadow {
    bottom: 40px;
    width: 80%;
  }
  .coinwrap {
    width: 200px;
  }
  .profilewrap {
    width: 130px;
    height: 130px;
  }
  .boosterimage {
    display: none;
  }
  .howitworks {
    padding: 40px 0;
  }
  .boostercontent h3 {
    margin-top: 40px;
  }
  .infographics {
    padding: 40px 0;
  }
  .chart-container {
    height: 260px;
  }
  .infographics h3 {
    line-height: 35px;
  }
  .three {
    left: 12.5%;
    height: 58%;
  }
  .four {
    left: 47.5%;
    height: 78%;
  }
  .devider {
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: auto;
  }
}

@media screen and (max-width: 767px) {
  .banner-sec {
    padding: 40px 0;
  }
  #banner1 .bannergraphics {
    bottom: -40px;
    width: 60%;
  }
  #banner1 h1 {
    font-size: 29px;
    line-height: 35px;
  }
  #banner1 h1 span  {
    font-size: 29px;
    line-height: 35px;
  }
  #banner1 h3 {
    font-size: 18px;
  }
  .btn-red {
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 700;
    padding: 10px 15px;
}
.unit-spacing-md > * {margin-bottom: 10px;}
.box-icon-classic-count {font-size: 40px;}
  .box-icon-classic-title{font-size: 15px;}
  #benefites-sipbooster h3, .infographics h3, .why-gsec h3.titleone {font-size: 24px;}
  .gapcontainer {margin: 15px 20%;}
  .breakingyear span { bottom: -10px;}
  .notegraph{    padding: 20px 15px;}  
  .faqimg-sec{display: none;}
  .tringleimg {
    bottom: -60px;
  }
  .bottomgra {
    bottom: -60px;
  }
  .featurebx {
    max-width: 47%;
  }
  .featurebx.even {
    margin-right: 0px;
  }
  .bullshadow {
    bottom: 40px;
    width: 80%;
  }
  .coinwrap {
    width: 175px;
  }
  .profilewrap {
    width: 130px;
    height: 130px;
  }
  .boosterimage {
    display: none;
  }
  .howitworks {
    padding: 40px 0;
  }
  .boostercontent h3 {
    margin-top: 40px;
  }
  .infographics {
    padding: 40px 0;
  }
  .infographics h3, .why-gsec h3.titleone {
    line-height: 35px;
    margin-bottom: 15px;
  }
  .chart-container {
    height: 260px;
  }
  #howitworks .container {
    width: 90%;
  }
  .devider {
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: auto;
  }
  .icount {
    width: 70px;
  }
  .three {
    left: 12.5%;
    height: 58%;
  }
  .four {
    left: 47.5%;
    height: 78%;
  }
}

@media screen and (max-width: 599px) {
  .banner-sec {
    padding: 40px 0;
  }
  #banner1 .bannergraphics {
    bottom: -40px;
    width: 60%;
  }
  #banner1 h1 {
    font-size: 20px;
    line-height: 25px;
    min-height: 1px;
  }
  #banner1 h1 span  {
    font-size: 20px;
    line-height: 25px;
  }
  #banner1 h3 {
    font-size: 18px;
  }
  #banner1 p {
    font-size: 14px;
    line-height: 18px;
  }
  .tringleimg {
    bottom: -48px;
  }
  .bottomgra {
    bottom: -48px;
  }
  .featurebx {
    max-width: 48%;
    padding: 15px;
    margin: 0 15px 15px 0;
  }
  .featurebx.even {
    margin-right: 0px;
  }
  .bullshadow {
    bottom: 40px;
    width: 80%;
  }
  .coinwrap {
    width: 145px;
    height: 130px;
  }
  .profilewrap {
    width: 100px;
    height: 100px;
  }
  .boosterimage {
    display: none;
  }
  .howitworks {
    padding: 40px 0;
  }
  .boostercontent h3 {
    margin-top: 10px;
  }
  .infographics {
    padding: 40px 0;
  }
  .infographics h3, .why-gsec h3.titleone {
    line-height: 35px;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 25px;
  }
  .chart-container {
    height: 260px;
  }
  .boostercontent h3 {
    font-size: 24px;
    line-height: 32px;  
    margin-bottom: 15px;
    text-align: center;
  }
  .featurebx h5 {
    font-size: 14px;
  }
  .howitworks p {
    font-size: 14px;
    line-height: 18px;
  }
  .howitworks h3 {
    font-size: 20px;
    line-height: 25px;
  }
  #howitworks .container {
    width: 90%;
  }
  .howtoworktable td {
    font-size: 13px;
    line-height: 16px;
  }
  .howtoworktable .head2 {
    font-size: 13px;
    line-height: 16px;
  }
  #bbenefits .container {
    width: 90%;
  }
  .colcolorpallet h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .growthofinv {
    padding: 40px 0;
  }

  .three {
    left: -6.5%;
    height: 58%;
  }
  .four {
    left: 29.5%;
    height: 78%;
  }
}

@media screen and (max-width: 479px) {
  .banner-sec {
    padding: 40px 0;
  }
  #banner1 .bannergraphics {
    bottom: -22px;
    width: 60%;
  }
  #banner1 h1 {
    font-size: 20px;
    line-height: 25px;
    min-height: 1px;
  }
  #banner1 h1 span  {
    font-size: 20px;
    line-height: 25px;
  }
  #banner1 h3 {
    font-size: 16px;
  }
  #banner1 p {
    font-size: 12px;
    line-height: 16px;
  }
  .tringleimg {
    bottom: -30px;
  }
  .bottomgra {
    bottom: -30px;
  }
  .featurebx {
    max-width: 100%;
    padding: 15px;
    margin: 0 0px 15px 0;
  }
  .featurebx.even {
    margin-right: 0px;
  }
  .featureicon {
    text-align: center;
  }
  .featurebx h5 {
    text-align: center;
  }
  .featurebx p {
    text-align: center;
  }
  .bullshadow {
    bottom: 40px;
    width: 80%;
  }
  .coinwrap {
    width: 105px;
    height: 130px;
  }
  .calenderwrap {
    width: 110px;
  }
  .calenderwrap img {
    width: 60%;
  }
  .calenderwrap p {
    font-size: 16px;
    margin-top: -7px;
    margin-left: -18px;
  }
  .profilewrap {
    width: 100px;
    height: 100px;
  }
  .boosterimage {
    display: none;
  }
  .howitworks {
    padding: 40px 0;
  }
  .boostercontent h3 {
    margin-top: 10px;
  }
  .infographics {
    padding: 40px 0 0;
  }
  .infographics h3, .why-gsec h3.titleone {
    line-height: 35px;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 32px;
  }
  .chart-container {
    height: 260px;
  }
  .boostercontent h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
    text-align: center;
  }
  .featurebx h5 {
    font-size: 14px;
  }
  .howitworks p {
    font-size: 12px;
    line-height: 16px;
  }
  .howitworks h3 {
    font-size: 20px;
    line-height: 25px;
  }
  #howitworks .container {
    width: 90%;
  }
  .howtoworktable td {
    font-size: 13px;
    line-height: 16px;
  }
  .howtoworktable .head2 {
    font-size: 13px;
    line-height: 16px;
  }
  #bbenefits .container {
    width: 90%;
  }
  .colcolorpallet h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .growthofinv {
    padding: 40px 0 0;
  }
  .notegraph p {
    font-size: 12px;
  }
  ul.faq-points li {
    font-size: 12px;
  }
  .three {
    left: -6%;
    height: 58%;
  }
  .four {
    left: 29.5%;
    height: 78%;
  }
}
