.Banner_bg {
  width: 100%;
  height: 420px;
  background: url("../assets/images/contact/building.svg") no-repeat center
    center;
  position: relative;
  background-size: cover;
  padding-top: 80px;
}

.four-boxes {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px 0 20px 30px;
  min-height: 152px;
  height: auto;
  margin-bottom: 30px;

  position: relative;
  -webkit-box-shadow: 0 3px 15px #000;
  box-shadow: 0 3px 15px #000;
}

.contact-heading {
  font-size: 18px;
  line-height: 24px;
  color: #21295a;
  border-left: 5px solid var(--accentBlue);
  padding: 8px 0 8px 25px;
  margin: 0 0 4px -30px;
  font-family: "Roboto";
  font-weight: 400;
}

.mailerDetail {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

.collertype {
  font-size: 16px;
  line-height: 21px;
  color: #6a738a;
  list-style-type: none;
  position: relative;
  display: inline-block;
  margin: 0 0 0 7px;
  width: 50px;
  font-family: "Roboto";
  font-weight: 400;
  vertical-align: top;
}

.collerDesc {
  width: calc(100% - 70px);
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 21px;
  font-family: "Roboto";
  font-weight: 400;
  color: #6a738a;
}
.collerDesc span {
  display: inline-block;
  margin-right: 5px;
}
.collerDesc a {
  color: #6a738a;
}

.border-seperator {
  border-left: 2px solid #6a738a;
  /* margin-left: 4px; */
  padding-left: 4px;
  position: static;
}

.center-contactHead {
  font-size: 18px;
  line-height: 24px;
  color: #21295a;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-family: "Roboto";
  font-weight: 400;
}

.link-click {
  color: #fb4d4d;
  text-decoration: underline;
}

.form-box {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px 40px;
  min-height: 335px;
  height: auto;
  position: relative;
  -webkit-box-shadow: 0 3px 15px #000;
  box-shadow: 0 3px 15px #000;
}

.form-tabBtn {
  background-color: #ffffff;
  /* border: 1px solid #E2E3E5; */
  border-radius: 50px;
  padding: 0;
  margin-top: -30px;
  margin-bottom: 25px;
}

.form-btn {
  font-size: 16px;
  text-align: center;
  color: #6a738a;
  background-color: #ffffff;
  border: none;
  padding: 4px;
  outline: none;
  width: 50%;
  float: left;
  border-radius: 50px;
  font-family: "Roboto";
  font-weight: 400;
}
.activeFormBtn {
  margin-top: -10px;
}
.activeFormBtn,
.form-btn:hover {
  color: #ffffff;
  background-color: #323b6d;
  border-radius: 23px;
  border: 1px solid #ffffff;
}

.investOnlineBox {
  display: none;
}

.form-outerSpace {
  margin-top: 15px;
}

.outer-top {
  margin-top: 55px;
}

.city-list {
  padding: 0 0 0 35px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.city-list li {
  list-style-type: none;
  font-size: 14px;
  line-height: 19px;
  color: #6a738a;
  background-color: #f5f5f5;
  border-radius: 21px;
  padding: 11px 20px;
  font-family: "Roboto";
  font-weight: 400;
  cursor: pointer;
}

.city-list li:first-child {
  background-color: transparent;
  color: #bebebe;
  cursor: default;
  padding: 11px 0;
}

.city-list li:hover,
.city-list li.active {
  background-color: #323b6d;
  color: #ffffff;
  transition: 1s;
}

.city-list li:first-child:hover {
  background-color: transparent;
  color: #bebebe;
}

.map-topSpace {
  margin-top: 20px;
  margin-bottom: 30px;
}

.inside-MapBox {
  margin-top: -175px;
}

.address-box {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px 15px;
  border-bottom: 5px solid var(--accentBlue);
  box-shadow: 0 3px 15px #000000;
  width: 100%;
}

.office-title {
  font-size: 14px;
  line-height: 19px;
  color: #6a738a;
}

.address-txt {
  font-size: 16px;
  line-height: 21px;
  color: #6a738a;
  width: 100%;
}

.bold-officeTxt {
  color: #21295a;
  font-weight: bold;
  font-family: "Roboto";
  font-weight: 400;
}

.disclamer-none {
  margin-bottom: 0;
}

/*  */
.faq_search {
  max-width: 554px;
  min-height: 60px;
  margin: auto;
  margin-bottom: 10px;
  position: relative;
}

.faqtxt {
  width: 77.5%;
  height: 50px;
  border: 1px solid #c3c7d0;
  background: #fff;
  border-radius: 30px;
  padding: 10px 15px;
  font-size: 16px;
  color: #6a738a;
  display: block;
  float: left;
  font-family: "Roboto";
  font-weight: 400;
}

.faqSearchbtn {
  width: 18.5%;
  height: 45px;
  display: block;
  border-radius: 30px;
  border: 0px;
  box-shadow: none;
  margin-left: 4%;
  float: left;
  background: url("../assets/images/faq/searchbar.svg") var(--primaryBlue) no-repeat center
    center;
}
.kfin_branch {
  text-align: left;
  color: #1a294e;
  font-size: 14px;
  margin-bottom: 25px;
  font-family: "Roboto";
}
.kfin_branch a {
  text-decoration: underline !important;
}
.yg_mt200 {
  margin-top: 200px;
}

.nameMAP {
  width: 100%;
  min-height: 400px;
}
.contactInput {
  margin-bottom: 0px !important;
}
.contactInput .formRe {
  height: 49px;
}
.btnRadius {
  margin-top: 10px;
}
@media (min-width: 768px) and (max-width: 992px) {
  .Banner_bg {
    height: auto;
    background-size: contain;
    background-position: top;
  }
  .yg_mt200 {
    margin-top: 50px;
  }
  .inside-MapBox {
    margin-top: 30px;
  }
  .city-list {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    padding-left: 20px;
  }
  .city-list li {
    display: inline-block;
    margin-right: 10px;
  }
  .address-box {
    margin-bottom: 20px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .Banner_bg {
    height: auto;
    background-size: contain;
    background-position: top;
    padding-top: 60px;
  }
  .yg_mt200 {
    margin-top: 50px;
  }
  .city-list {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    padding-left: 10px;
  }
  .city-list li {
    display: inline-block;
    margin-right: 10px;
  }
  .inside-MapBox {
    margin-top: 30px;
  }
  .address-box {
    margin-bottom: 20px;
  }
}
