.mainContainerMR {
    margin: auto;
}

.mainContainerMRArticleLabel {
    color: #25375b;
    font-weight: 400;
    margin-bottom: 20px;
}

.totalRecAlign {
    margin-top: auto;
}

.close_icon {
    width: 14px;
    height: 14px;
    display: block;
    background: url("../assets/images/download/close.svg") no-repeat center center;
    background-position: 100% 100%;
    background-size: contain;
    position: absolute;
    top: 14px;
    right: -6px;
    cursor: pointer;
}

.videoContainer {
    margin: 0 auto;
    max-width: 700px;
    width: auto;
    height: auto;
}

.videoContainer iframe {
    width: 100%;
    height: 400px;
}

.videoTitle {
    margin: 10px auto;
    max-width: 700px;
    color: #000000;
}

.videoListContainer {
    clear: both;
    overflow: hidden;
    margin: 0;
    margin-bottom: 20px;
    padding: 2%;
    list-style: none;
    display: flex;
    justify-content: center;
    /* width: 100%; */
    /* background: #f2f2f2; */
    /* text-align: center; */
    /* -webkit-box-shadow: inset 0px 0px 20px -3px rgb(0 0 0 / 52%);
    -moz-box-shadow: inset 0px 0px 20px -3px rgba(0, 0, 0, 0.52);
    box-shadow: inset 0px 0px 20px -3px rgb(0 0 0 / 52%); */
}

.videoListItem {
    border: 2px solid #c7c7c7;
    background: #d8d8d8;
    width: 22%;
    /* float: left; */
    padding: 2px;
    margin-bottom: 5px;
    margin-left: 1%;
    margin-right: 1%;
    list-style: none;
    vertical-align: top;
    display: inline-block;
    cursor: pointer;
}

.videoListItem img {
    width: 100%;
}

.videoListItem p {
    text-align: justify;
    margin-bottom: 10px;
    line-height: 20px;
}

.videoListItem p .videoTitle {
    /* display: block; */
    color: #000000;
}

.videoListItem p .videoDate {
    display: block;
    color: #646464;
    font-size: 12px;
    margin-bottom: 15px
}

.mediaRoomAnchor {
    color: #1a294e;
    cursor: pointer;
}

.mediaRoomAnchor:hover {
    color: #1a294e;
    text-decoration: none;
    opacity: 0.7;
}


@media only screen and (min-width: 230px) and (max-width: 551px) {

    .videoListContainer {
        display: block;
    }

    .videoListItem {
        width: 45%;
    }

    .videoContainer iframe {
        height: 200px;
    }

    .mainContainerMRArticleLabel {
        font-size: 18px;
    }
}