.innerTab-list {
  padding: 0;
  margin: 0 0 50px;
  display: flex;
  justify-content: space-around;
}

.innerTab-list li {
  list-style-type: none;
  /* border: 1px solid #ffffff; */
  border-radius: 4px;
  display: inline-block;
  padding: 10px 25px;
  /* background-color: #ffffff; */
  width: 25%;
  margin: 0 5px;
  cursor: pointer;
}

.innerTab-list li:first-child {
  margin-left: 0;
}

.innerTab-list li:last-child {
  margin-right: 0;
}

.innerTab-list li:hover {
  border: 1px solid #ffc107;
  transition: 0.5s;
}

.activeInnerTab {
  background-color: #ffffff !important;
  position: relative;
  border: 1px solid #ffc107 !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.col-center {
  float: none;
  clear: both;
  margin-left: auto;
  margin-right: auto;
}

/* switch button */

.switch-tabBtn {
  background-color: #ffffff;
  border: 1px solid #e2e3e5;
  border-radius: 50px;
  padding: 0;
  margin: -18px 0 60px;
  z-index: 9;
}

.sip-btn {
  font-size: 16px;
  text-align: center;
  color: #6a738a;
  background-color: #ffffff;
  border: none;
  padding: 5px;
  outline: none;
  width: 50%;
  /* float: left; */
  border-radius: 50px;
  font-family: 'Roboto';
font-weight: 400;
}

.activeMainBtn {
  color: #ffffff;
  background-color: #323b6d;
  border-radius: 23px;
}
/* .sip-btn:hover {
    color: #ffffff;
    background-color: #323b6d;
    border-radius: 23px;
} */

.duration-btn {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  padding: 7px 25px;
  border-radius: 35px;
  /* float: left; */
  margin-right: 10px;
  color: #6a738a;
  outline: none;
}

.activeBtn,
.duration-btn:hover {
  border: 1px solid #fb4d4d !important;
  color: #1a294e !important;
}

.activeBtnSecond,
.duration-btn:hover {
  border: 1px solid #fb4d4d;
  color: #1a294e;
}

.activeBtnCalc,
.duration-btn:hover {
  border: 1px solid #fb4d4d;
  color: #1a294e;
}

/* magic slider */

.value-range {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  width: 138px;
  height: 63px;
  border-radius: 35px;
  color: #1a294e;
  position: relative;
  font-size: 18px;
  line-height: 18px;
  margin-top: -30px;
  margin-bottom: 35px;
  font-family: 'Roboto';
font-weight: 400;
  text-align: center;
}

.inside-amount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .amountCal {
    width: 100%;
    height: 100%;
    border: 0px;
    text-align: center;
} */
.amountCal {
  width: 90%;
  height: 55%;
  border: 0px;
  text-align: center;
  margin-top: 15px;
}
.amountCal:focus{
  outline: none;
}

@media(min-width:320px) and (max-width:767px) {
    .upside-downValue {
        /* display: flex;
        flex-direction: column-reverse;
        margin-bottom: 20px; */
    }

    .value-range {
        margin: 0 auto 20px;
        width: 85% !important;
        /* height: 40px !important; */
        margin-bottom: 15px !important;
        margin-top: 0px !important;
    }

}

@media(min-width:768px) and (max-width:992px) {
    .value-range {
        width: 100px !important;
    }
}

.calculate-bg {
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 10px 0 0 10px;
  border-right: transparent;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 0 55px;
  margin-top: 10px;
  min-height: 447px;
}

.min-amount {
  font-size: 11px;
  color: #bfbfbf;
  line-height: 11px;
  text-align: left;
  margin: 0;
  float: left;
}

.max-amount {
  font-size: 11px;
  color: #bfbfbf;
  line-height: 11px;
  text-align: right;
  margin: 0;
  float: right;
}

.calculate-div {
  width: 100%;
  font-size: 18px;
  line-height: 18px;
}
.calculate-div input#number {
  background-color: #ffffff;
}

.percent-sign {
  vertical-align: middle;
}

.duration-btn {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  padding: 7px 25px;
  border-radius: 35px;
  float: left;
  margin-right: 10px;
  color: #6a738a;
  outline: none;
}

.value-button {
  display: inline-block;
  color: #8585a3;
  margin: 0px;
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.singleLine {
  white-space: nowrap;
  padding-left: 0px;
}

.value-button:hover {
  cursor: pointer;
}

form #decrease {
  margin-right: 4px;
}

form #increase {
  margin-left: 4px;
  font-weight: 500;
}

form #input-wrap {
  margin: 0px;
  padding: 0px;
}

input#number {
  text-align: center;
  border: none;
  margin: 0px;
  width: 50px;
  height: 40px;
  vertical-align: middle;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.upside-downValue {
  margin-bottom: 30px;
}

@media (min-width: 767px) and (max-width: 991px) {
  /* .upside-downValue {
       display: flex;
       flex-direction: column-reverse;
   } */
  .calculate-bg {
    margin-bottom: 20px;
  }

  .tab-text {
    font-size: 12px;
    padding-left: 0px !important;
  }
  .calculate-div {
    font-size: 17px;
  }
  .tab-text {
    margin-top: 3px;
  }
  .tablinks .col-md-7 {
    padding: 0px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .innerTab-list {
    padding: 0;
    margin: 0 0 50px;
    display: block;
    overflow: auto;
    white-space: nowrap;
    justify-content: space-around;
  }

  .duration-btn {
    float: none;
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 5px;
  }

  input#number {
    width: 35px;
    padding: 2px;
  }

  .calculate-bg {
    margin-top: 35px;
    padding: 0 30px;
    border-radius: 10px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
}

button:focus,
button:focus-visible,
button:active {
  outline: none;
}

.tab-list {
    padding: 0;
    margin: 0 0 50px;
    display: flex;
    justify-content: space-around;
}

.tab-list li {
    list-style-type: none;
    border: 2px solid rgba(204, 204, 204, 0.5);
    border-radius: 4px;
    display: inline-block;
    padding: 14px 12px;
    background-color: transparent;
    width: 25%;
    color: #FFFFFF;
    margin: 0 30px;
    cursor: pointer;
    transition: 0.5s;
}
.toolshome-sec .tab-list li {
    position: relative;
    transition: all 0.2s ease-out;
    /* color: #1E3879 ; */
    z-index: 0;
    overflow: hidden;
    color: #fff;
    box-shadow: 0px 4px 8px rgb(38 38 38 / 20%);
    background-color: #FFFFFF;
    border: 0px;
}
.toolshome-sec .tab-list li:hover{ color: #fff;box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);background-color: #FFFFFF ; border: 0px;}
/* .toolshome-sec .tab-list li:hover .tab-text{color: #fff;} */
.toolshome-sec .tab-list li:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -30px;
    right: -30px;
    background: #dee4f9;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}
.toolshome-sec .tab-list li:hover:before{transform: scale(21);}
.toolshome-sec h1.headingH1{font-family: 'Roboto';
  font-weight: 700;}
.tab-list li:first-child {
    margin-left: 0;
}

.tab-list li:last-child {
    margin-right: 0;
}

.innerTab-list {
    padding: 0;
    margin: 0 0 50px;
    display: flex;
    justify-content: space-around;
}

/* .innerTab-list li {
    list-style-type: none;
    border: 1px solid #ffffff;
    border-radius: 4px;
    display: inline-block;
    padding: 10px 12px;
    background-color: #FFFFFF;
    width: 25%;
    margin: 0 5px;
    cursor: pointer;
}

.innerTab-list li:first-child {
    margin-left: 0;
}

.innerTab-list li:last-child {
    margin-right: 0;
}

.innerTab-list li:hover {
    border: 1px solid #FFC107;
    transition: 0.5s;
}*/

.tab-text {
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    padding-left: 5px;
    margin: 10px 0 0;
    /* word-wrap: break-word; */
    font-family: 'Roboto';
font-weight: 500;
}

.tab-image {
    display: inline-block;
}

.col-center {
    margin: 0 auto;
    display: block;
    float: none;
    clear: both;
}

.activeTab {
    background-color: #FFFFFF !important;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    color: #1E3879 !important;
    border: 2px solid #FFFFFF !important;
    transition: 0.5s !important;
}

.activeTab::after {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    background: #fff;
    transform: rotate(45deg);
    left: 40%;
    bottom: -16px;
    box-shadow: 3px 3px 1px -3px rgba(0, 0, 0, 0.2);
}

.tablinks a {
    /* color: #fff; */
    color: #1E3879;
    display: block;
    width: 100%;
    height: 100%;
    clear: both;
}

.activeTab .tab-text {
    color: #1E3879 !important;
}


/* slider ta */

.calculate-bg {
    background-color: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 10px 0 0 10px;
    border-right: transparent;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 0 55px;
    margin-top: 10px;
    min-height: 447px;
}

/* tools */
.calculator-buttons {
    margin-top: 58px;
}

.money-bg {
    background-color: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 30px 0;
    min-height: 466px;
}

.dark-greytxt {
    color: #6A738A !important;
    text-decoration: none;
    font-size: 14px !important;
    font-family: 'Roboto';
font-weight: 400;
}

.rupee-amount {
    line-height: 32px;
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    word-wrap: break-word;
    font-family: 'Roboto';
  font-weight: 700;
    color: #1A294E;
}

.innerPadding-Tab {
    padding: 55px !important;
}

@media(min-width:320px) and (max-width:767px) {

    /* tab nav */
    .tab-list li {
        padding: 5px 8px;
        display: block;
        float: left;
        width: 50%;
        margin: 0px;
    }

    .overflow-flexBox {
        display: block;
        justify-content: none;
        margin: 0px;
    }

    .activeTab::after {
        background: none;
    }



    /* tab nav end */

    /* .overflow-flexBox {
    flex-wrap: nowrap;
    flex: 1;
    display: flex;
    overflow: auto;
    justify-content: start;
} */

    .overflow-flexBox li {
        display: inline-block;
        width: 75%;
        min-height: 63px;
        float: none;
        margin: 1.5% !important;


    }

    .overflow-flexBox li .tab-image {
        width: 41px;
    }

    .overflow-flexBox li:nth-child(1) {
        /* margin-left: 0 !important; */
    }

    .tab-text {
        text-align: center;
        font-size: 12px;
    }

    .money-bg {
        background-color: transparent;
        border: none;
        box-shadow: none;
        min-height: auto;
    }

    /* .money-bg img {
        display: none
    } */

    /*  */
    .calculate-bg {
        padding: 0 10px 10px !important;
        border-radius: 10px;
    }

    .calculator-buttons {
        margin-top: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .tab-list li {
        margin: 0px 10px
    }

    .tab-image {
        display: block;
        margin: auto;
    }

    .calculate-bg {
        padding: 0 25px !important;
    }

    .tab-text {
        text-align: center;
    }


}