/* TAB Start */
.no_bg_before:before {
  content: none;
}

.bgacc {
  background-color: transparent;
  min-height: 560px;
  position: relative;
  padding-left: 30px;
}

.bgAccount {
  background-color: #f4f6fb;
}

.bgAccount:before {
  background-image: url("../assets/images/account/accountBG.svg");
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  opacity: 0.1;
  background-position: right bottom;
  background-size: 40%;
  z-index: 0;
  bottom: 0;
  right: 0;
}

/* TAB END  */

/* MEDIA FOLDER */
.colKey_right {
  width: 100%;
  height: 110px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #eeeeee;
  background: #fff;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.direRight {
  width: 13px;
  height: 10px;
  display: block;
  background: url("../assets/images/landing/rightarrow.svg") no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 999;
  top: 48%;
  transition: all 0.3s ease;
  right: 4%;
  background-position: 100% 100%;
}

.colKey_right:hover .direRight {
  right: 2%;
}

.key1_img {
  height: 110px;
  /* background: url('./images/key1.jpg') no-repeat center; */
}

.key1_img img {
  width: 100%;
}

.key_05 {
  font-size: 16px;
  color: #1a294e;
  font-family: 'Roboto';
font-weight: 400;
  margin: 0px;
  line-height: 19px;
}

.key1_img .key_imgPlay {
  width: 25px;
  height: 25px;
}

.paddlR {
  padding: 6% 6%;
  position: relative;
  box-sizing: border-box;
}

.key_imgPlay {
  width: 46px;
  height: 46px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.playicon_btn {
  width: 80px;
  height: 80px;
  background: url("../assets/images/landing/playicon.svg");
  background-size: contain;
  margin: 20px auto;
  display: block;
  cursor: pointer;
}
@media (min-width: 320px) and (max-width: 767px) {
  .key1_img img {
    max-width: 100%;
    width: auto;
  }

  .colKey_right,
  .key1_img {
    height: auto;
  }

  .paddlR {
    padding-top: 4px;
    padding-bottom: 0px;
  }

  .key_05 {
    font-size: 11px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .key1_img img {
    max-width: 100%;
    width: auto;
  }

  .key_05 {
    font-size: 14px;
  }
}

/* MEDIA FOLDER END */

/* ACCORDIAN  FOLDER START */

.custAccord {
  border: 1px solid #dadbde;
  border-radius: 4px;
  margin-bottom: 30px;
  background: #ffffff;
  transition: all 0.3s ease;
}

.custAccord h3 {
  font-size: 16px;
  font-family: 'Roboto';
font-weight: 500;
  color: #1a294e;
  margin: 0px;
  position: relative;
  padding: 25px 36px 25px 20px;
}

.custAccord .colArrow {
  width: 7px;
  height: 12px;
  position: absolute;
  display: block;
  right: 5%;
  transition: all 0.3s ease;
  top: 45%;
  background: url("../assets/images/landing/rightarrow.svg") no-repeat center
    center;
  background-size: contain;
  background-position: 100% 100%;
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.custAccord .innerAccord {
  font-size: 14px;
  font-family: 'Roboto';
font-weight: 400;
  color: #6a738a;

  transition: all 0.3s ease;
  display: none;
  height: 0px;
  opacity: 0;
  padding: 25px 20px;
  padding-top: 0px;
}

.custAccord .innerAccord p {
  margin-bottom: 0px;
}

.custAccord.custAccord_Open .innerAccord {
  display: block;
  height: auto;

  opacity: 1;
}

.custAccord_Open .colArrow {
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

@media (min-width: 320px) and (max-width: 767px) {
  .custAccord h3 {
    font-size: 14px;
  }
}

/* ACCORDIAN  FOLDER END */

/* PMS START */
.leadership-waveBG {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background: url("../assets/images/pms/RightWave.png") no-repeat;
  padding-bottom: 70px;
  margin-top: 90px;
  margin-bottom: 90px;
  background-position: bottom right;
}

.chairman-outside {
  margin-left: -65px;
  margin-top: -25px;
  width: 200px;
}

.leadership-heading {
  font-size: 24px;
  color: #1a294e;
  margin: 0 0 5px;
  font-family: 'Roboto';
  font-weight: 700;
}

.leadership-subHeading {
  font-size: 18px;
  margin: 0 0 20px;
  color: #1a294e;
}

.leadership-information {
  font-size: 16px;
  color: #6a738a;
  margin: 0 0 15px;
  line-height: 22px;
}

.pad-leadership {
  padding: 45px 30px 0 0;
}

.pad0 {
  padding: 0;
}

.right-social {
  float: right;
}
@media (min-width: 768px) and (max-width: 992px) {
  .blue-title {
    font-size: 40;
    line-height: 35px;
  }
  .leadership-waveBG {
    margin-top: 40px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .chairman-outside {
    margin-left: -7px;
    margin-top: -10px;
  }
  .leadership-waveBG {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .pad-leadership {
    padding: 12px 10px 0 0;
  }
  .leadership-heading {
    font-size: 18px;
  }
  .leadership-subHeading {
    font-size: 16px;
  }
  .pmstree {
    background-position: 30% 1%;
  }
}

@media (min-width: 320px) and (max-width: 320px) {
  /* Experienced Leadership & Management CSS Starts Here */
  .chairman-outside {
    margin-left: -25px;
    margin-top: -10px;
  }

  .pad-leadership {
    padding: 15px 10px 15px 0;
  }

  .leadership-heading {
    font-size: 16px;
  }

  .leadership-subHeading {
    font-size: 14px;
  }

  .leadership-information {
    font-size: 14px;
  }

  .leadership-waveBG {
    background-size: contain;
    padding-bottom: 15px;
    margin-top: 15px;
  }

  .content-box {
    display: contents;
  }

  .right-social {
    float: none;
  }
}

.pad-60 {
  padding: 15px 60px;
}

.pmsStr {
  margin-bottom: 25px;
  min-height: 242px;
  border-radius: 4px;
  border-bottom: 6px solid transparent;
}

.pmsStr:hover {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-bottom: 6px solid #ffc107;
  background-image: none;
}

.slideWidth {
  width: 100% !important;
}

.first-tab {
  /* background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-bottom: 6px solid #FFC107; */
  background-image: linear-gradient(to right, #e5d3e4, #dfe3ef);
}

.second-tab {
  background-image: linear-gradient(to right, #e5d9d3, #dce1ef);
}

.third-tab {
  background-image: linear-gradient(to right, #d3e5d5, #dce0ee);
}

.fourth-tab {
  background-image: linear-gradient(to right, #d3e5d5, #dce0ee);
}

.fifth-tab {
  background-image: linear-gradient(to right, #e8dcd0, #ece9d7);
}

.minheight {
  min-height: 300px;
}

.portfolio-heading {
  font-size: 20px;
  color: #1a294e;
  margin: 0 -5px 20px 0;
  line-height: 28px;
  font-family: 'Roboto';
font-weight: 400;
}

.padding-box {
  padding: 30px 0 0;
}

.graphTabs {
  padding-left: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px dashed #e2e2e2;
}
.graphTabs li {
  display: inline;
  width: 20%;
  min-height: 100px;
  padding: 15px 20px;
  cursor: pointer;
  border-right: 1px dashed #e2e2e2;
}
.graphTabs li:last-child {
  border-right: 0px;
}
.activeGraphTab {
  border-bottom: 3px solid #fcb017;
  box-shadow: 0px 5px 15px 5px #e2e2e2;
}
.graphTabs li h4 {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  color: #1a294e;
}
.graphTabs li p {
  margin-bottom: 0px;
  font-family: 'Roboto';
font-weight: 400;
  font-size: 14px;
  color: #6a738a;
  margin-top: 5px;
}
.cagrRed {
  color: #de0d0d !important;
}
.cagrGreen {
  color: #019401 !important;
}

@media (min-width: 768px) and (max-width: 992px) {
  .portfolio-heading {
    font-size: 18px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  /* PMS Strategies CSS Starts Here */
  .pad-60 {
    padding: 15px;
  }

  .padding-box {
    padding: 25px 0 20px;
    margin-bottom: 30px;
  }

  .pms-rightIcon {
    float: right;
  }

  .portfolio-information {
    clear: both;
  }

  .portfolio-heading {
    font-size: 18px;
    margin: 0 -20px 20px 0;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .graphTabs {
    overflow-x: auto;
  }
  .graphTabs li {
    width: 200px;
    flex: 10 0 auto;
  }
}

/* PMS END */

/* login input */
.label-textField {
  margin-bottom: 20px;
}
.label-textField .label {
  width: 100%;
  font-size: 15px;
  color: gray;
}
.label-textField .text-field {
  width: 100%;
}
.label-textField .error-message {
  width: 100%;
}

/* end login input */

/* Main Wrapper Start */
.fund-details-sec {
  font-family: 'Roboto';
font-weight: 400;
}

.fund-name-dropdown {
  border: 0;
  font-size: 25px;
  color: #09327f;
  border-bottom: 0px solid #bfc3ce;
  /* line-height: 40px; */
  padding-right: 10px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  width: 85%;
}
select.fund-name-dropdown option {
  font-size: 15px;
  z-index: 100000;
}
.newfunddetailssec .fund-name-dropdown {
  width: 100%;
}

.input-lable {
  color: #adafb6;
  font-size: 12px;
  font-family: 'Roboto';
font-weight: 400;
  text-transform: uppercase;
  font-weight: 500;
}
.fund-details-sec .nav-sec {
  padding: 0px 20px;
}

.nav-sec .navarrow {
  vertical-align: inherit;
  height: 11px;
  margin-right: 3px;
}
.prefilledtxtbox {
  font-size: initial;
}
.folio-hname {
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.parametertitle {
  color: #141b2f;
  font-size: 16px;
  margin: 5px 0 5px;
}

.smalllabel {
  color: #a6aab5;
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.rupeesign {
  font-size: 80%;
  padding-right: 3px;
}

.pos-rel {
  position: relative !important;
}

/* .help-tip p { */
.infoHoverText {
  display: none;
  text-align: left;
  background-color: #333;
  padding: 10px 15px;
  width: 205px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  left: 150px;
  top: 60px;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  z-index: 3;
  text-transform: none;
  margin-bottom: 0;
}

.infoHoverText:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #333;
  left: 10px;
  top: -12px;
}

.infoHoverText:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}

.help-tip {
  display: inline-block;
  margin-left: 0;
  vertical-align: middle;
  cursor: pointer;
}

.help-tip img.info-icon {
  width: 11px;
  vertical-align: baseline;
}

.help-tip:hover .infoHoverText {
  display: block;
  transform-origin: 100% 0;
  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
}

/* tool tip  */
.tooltipn {
  position: relative;
  cursor: pointer;
}
.tooltip-item::after {
  content: "";
  position: absolute;
  width: 260px;
  height: 20px;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.tooltip-item > img {
  width: 11px;
  vertical-align: middle;
}

.tooltipn:hover ~ .tooltip-item::after {
  pointer-events: auto;
}

.tooltip-content {
  position: absolute;
  z-index: 9;
  width: 215px;
  /* left: 135px; */
  left: 30px;
  top: auto;
  bottom: -143px;
  text-align: left;
  font-size: 12px;
  line-height: normal;
  box-shadow: 0px 10px 25px rgba(48, 54, 61, 0.35);
  background: #fff;
  display: none;
  cursor: default;
  pointer-events: none;
  padding: 15px;
  border-radius: 0.25rem;
}
.tooltip-content-3 {
  position: absolute;
  z-index: 9;
  width: 215px;
  /* left: 135px; */
  left: 220%;
  top: -28px;
  text-align: left;
  font-size: 12px;
  line-height: normal;
  box-shadow: 0px 10px 25px rgba(48, 54, 61, 0.35);
  background: #fff;
  display: none;
  cursor: default;
  pointer-events: none;
  padding: 15px;
  border-radius: 0.25rem;
}
.tooltip-effect-2 ~ .tooltip-content {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-3 ~ .tooltip-content-3 {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltipn:hover .tooltip-content {
  pointer-events: auto;
  display: block;
  pointer-events: auto;
  -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  opacity: 1;
}
.tooltipn:hover .tooltip-content-3 {
  pointer-events: auto;
  display: block;
  pointer-events: auto;
  -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  opacity: 1;
}
.tooltip-content::after {
  content: "";
  top: 63%;
  left: -20px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-right-color: #fff;
  border-width: 10px;
}
.tooltip-content-3::after {
  content: "";
  top: 8%;
  left: -20px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-right-color: #fff;
  border-width: 10px;
}

/* Tooltip content*/
.tooltip-content img {
  margin: 0 auto;
  display: block;
  padding-bottom: 10px;
}

.tooltip-text {
  font-size: 12px;
  line-height: normal;
  font-family: 'Roboto';
font-weight: 400;
  display: block;
  color: #616161;
}
.tooltip-text > p {
  font-family: 'Roboto';
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}
.tooltip-text > small {
  padding-bottom: 10px;
  font-size: 10px;
  line-height: normal;
  color: #999;
}
.tooltip-text ul {
  list-style: disc;
  padding-left: 15px;
  margin-bottom: 10px;
}

/* formSection */
.folio-tab-sec {
  border-bottom: 2px solid #eaecf1;
  float: left;
  width: 100%;
  padding: 0px 25px 0;
}

.smalltitle-img {
  background-color: #fafafa;
  border: 2px solid #eaecf1;
  border-bottom-color: transparent;
  padding: 10px 20px;
  margin: 0 10px -1px 0;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px 5px 0 0;
  z-index: 0;
  position: relative;
  transition: all 0.3s ease 0s;
  float: left;
}
.mt-10 {
  margin-top: 10px;
}

.folio-tabinput-sec {
  position: relative;
  clear: both;
  background-color: #fafafa;
  padding: 20px 25px 35px;
  border-radius: 0 0 3px 3px;
}

.smalltitle-img:before {
  content: "";
  display: block;
  height: 2px;
  background: #fafafa;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  transform: scaleX(1);
  transition: all 0.3s ease-in-out 0s;
}

.txtbox {
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: none;
  border-bottom: 1px solid #bfc3ce;
  font-size: 14px;
  color: #323b6d;
  font-family: 'Roboto';
font-weight: 400;
}

select.txtbox {
  padding-right: 25px;
}

.smalltitle-img h2 {
  display: inline-block;
  text-transform: uppercase;
  color: #27306b;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 700;
  padding-left: 10px;
  vertical-align: middle;
  margin: 0 0;
}

.smalltitle-img img {
  width: 20px;
}

/* end col-4 */
/* .sidebar-icon-only .fund-details-circle {
  padding: 90px 25px 35px;
  margin-top: 40px;
  min-height: 305px;
} */
.fund-details-circle {
  padding: 70px 25px 30px;
  margin-top: 68px;
  min-height: 275px;
}
.fund-details-circle-1 {
  padding: 70px 25px 30px;
  margin-top: 68px;
  min-height: 275px;
}

.counter {
  width: 230px;
  height: 230px;
  text-align: center;
  border-radius: 100%;
  padding: 5px;
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: absolute;
  top: -55px;
  left: 0;
  right: 0;
}

.counter:before,
.counter:after {
  content: "";
  background: #dde6f7;
  width: 90%;
  height: 90%;
  border-radius: 100%;
  /*    box-shadow: -5px 5px 5px rgba(0,0,0,0.3);*/
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
}

.counter:after {
  background: #f3f7fd;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 10px rgb(221 230 247);
  transform: translate(0);
  top: 0%;
  left: 0;
  z-index: -2;
  /*    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 50%);*/
}

.counter:hover .counter-icon i.fa {
  transform: rotateX(360deg);
}

.counter .fund-label {
  font-size: 14px;
  font-family: 'Roboto';
font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 3px;
}

.counter .fund-value {
  font-size: 30px;
  font-weight: 700;
  font-family: "robotoblack";
  padding: 20px 0 0;
}

.counter .fund-smallvalue {
  font-size: 22px;
  font-family: 'Roboto';
font-weight: 400;
}

.counter-1 {
  width: 230px;
  height: 230px;
  text-align: center;
  border-radius: 100%;
  padding: 5px;
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: absolute;
  top: -55px;
  left: 0;
  right: 0;
}
.counter-1:before,
.counter-1:after {
  content: "";
  background: #dde6f7;
  width: 90%;
  height: 90%;
  border-radius: 100%;
  /*    box-shadow: -5px 5px 5px rgba(0,0,0,0.3);*/
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
}

.counter-1:after {
  background: #f3f7fd;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 10px rgb(221 230 247);
  transform: translate(0);
  top: 0%;
  left: 0;
  z-index: -2;
  /*    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 50%);*/
}

.counter-1:hover .counter-icon i.fa {
  transform: rotateX(360deg);
}

.counter-1 .fund-label {
  font-size: 14px;
  font-family: 'Roboto';
font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 3px;
}

.counter-1 .fund-value {
  font-size: 30px;
  font-weight: 700;
  font-family: "robotoblack";
  padding: 20px 0 0;
}

.counter-1 .fund-smallvalue {
  font-size: 22px;
  font-family: 'Roboto';
font-weight: 400;
}

/* clip start */
.conneting-strings,
.conneting-strings-sm {
  position: relative;
  /* z-index: 1; */
}

.conneting-strings img:first-child {
  position: absolute;
  top: -20px;
  left: 40px;
}

.conneting-strings img:last-child {
  position: absolute;
  top: -20px;
  right: 40px;
}

.conneting-strings-sm img:first-child {
  position: absolute;
  top: -15px;
  left: 40px;
}

.conneting-strings-sm img:last-child {
  position: absolute;
  top: -15px;
  right: 40px;
}

/* clip end */

/* main body */
.main-body {
  padding: 25px 25px 40px;
  background-color: #fff;
  margin-top: 38px;
}

/* title css */

.title-img h2 {
  display: inline-block;
  text-transform: uppercase;
  color: #27306b;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 700;
  padding-left: 10px;
  vertical-align: middle;
  margin: 0 0;
}
.addmoney-title {
  float: left;
}

/* title css end */
/* border css */
.border-md-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

/* yogesh css */
.yg_newFundWrapper {
  display: none;
}
.yg_investTabs {
  margin-top: 20px;
}
.yg_investTabs ul {
  list-style-type: none;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  align-items: flex-end;
  flex-wrap: wrap;
}
.yg_investTabs ul li {
  text-align: center;
  max-width: 200px;
}
.yg_investTabs ul li:first-child {
  margin-right: 20px;
}
.yg_investTabs ul li label {
  transition: all 0.3s ease-in-out;
  color: #999;
  text-transform: uppercase;
  font-size: 14px;
  background-color: #ededed;
  padding: 10px 15px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  border-radius: 10px 10px 0px 0px;
  border-top: 1px solid #e8e9ee;
  border-bottom: 0px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.yg_investTabs ul li label img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.5;
  margin-right: 10px;
  height: 28px;
}
.yg_activeInvestTab label {
  cursor: default !important;
  color: #27306b !important;
  background-color: #fff !important;
  font-family: 'Roboto' !important;
  font-weight: 700 !important;
  box-shadow: 0px 0px 10px 0px #ccc;
}
.yg_activeInvestTab label img {
  -webkit-filter: grayscale(0) !important;
  filter: grayscale(0) !important;
  opacity: 1 !important;
}
.yg_inputText {
  height: 38px;
  box-shadow: none;
  color: #21295a;
  font-family: 'Roboto';
font-weight: 400;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #bfc3ce;
  width: 100%;
  padding: 5px 7px 7px 5px;
  outline: 0px;
  background-color: transparent;
}
.yg_inputText:focus {
  outline: 0px;
  box-shadow: none;
  background-color: transparent;
  border-color: #bfc3ce;
}
.yg_newFundImg {
  padding: 70px 0px 0px 0px;
}

.lessPad {
  padding: 0px 8px;
}
/* responsive css */
@media (max-width: 767px) {
  .main-body {
    padding: 25px 5px 40px;
  }
  .title-img img.img-fluid {
    width: 10%;
    display: inline-block;
  }
  .title-img h2 {
    width: 90%;
    font-size: 16px;
  }
  .border-md-bottom {
    border-bottom: 0px solid #dee2e6 !important;
  }
  .amt-title-center {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .switch-parfull .custom-checkbox.normal-custom-checkbox {
    margin: 10px auto 0;
  }
  .fund-name-dropdown {
    font-size: 14px;
    width: 100%;
  }
  .fund-name-dropdown ~ span.d-inline.input-lable.color-lightblue {
    display: none !important;
  }
  .nav-sec.border-left {
    border-left: 0 !important;
  }
  .help-tip p {
    right: 20px;
    top: 28px;
  }
  .help-tip p:before {
    left: auto;
    right: 50%;
  }
  .yg_investTabs ul li {
    width: 46%;
  }
  .yg_investTabs ul li label {
    font-size: 12px;
    text-align: center;
  }
  .yg_investTabs ul li label img {
    height: 25px;
    display: block;
    margin: 0 auto 10px;
  }
  .counter {
    top: 15px;
  }
  .counter-1 {
    top: 15px;
  }
  .fund-details-circle {
    padding: 140px 25px 35px;
  }
  .fund-details-circle-1 {
    padding: 140px 25px 35px;
  }
  .addmoney-title {
    float: none;
  }
  .prefilledtxtbox {
    margin-bottom: 10px;
  }
  .bankstrip-sec .form-group {
    margin-top: 15px;
  }
  .authstrip-sec .row .otpstrip-sec {
    margin: 10px 0 0;
  }
  .authstrip-sec .row {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .profitgreen {
    width: 7px;
    height: 10px;
    background: url("/assets/images/dash/green.svg") no-repeat center;
    background-size: cover;
    display: inline-block;
  }
  .lossred {
    width: 7px;
    height: 10px;
    background: url("/assets/images/dash/red.svg") no-repeat center;
    background-size: cover;
    display: inline-block;
  }
  .profitgreen.navarrow,
  .lossred.navarrow {
    height: 11px;
    margin-right: 3px;
  }
  .sidebar-icon-only .fund-details-circle {
    margin-top: 125px;
  }
  .sidebar-icon-only .fund-details-circle-1 {
    margin-top: 125px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .fund-details-circle {
    padding: 140px 25px 35px;
    margin-top: 110px;
  }
  .fund-details-circle-1 {
    padding: 140px 25px 35px;
    margin-top: 110px;
  }
  .counter {
    top: 15px;
  }
  .counter-1 {
    top: 15px;
  }
  .main-body {
    padding: 25px 10px 40px;
  }
  .nav-sec {
    padding: 5px 20px 0px 20px;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .addmoney-title {
    float: none;
  }
  .counter {
    width: 190px;
    height: 190px;
  }
  .counter .fund-value {
    font-size: 20px;
  }
  .counter .fund-label {
    font-size: 11px;
  }
  .counter .fund-smallvalue {
    font-size: 18px;
  }
  .counter-1 {
    width: 190px;
    height: 190px;
  }
  .counter-1 .fund-value {
    font-size: 20px;
  }
  .counter-1 .fund-label {
    font-size: 11px;
  }
  .counter-1 .fund-smallvalue {
    font-size: 18px;
  }
  .fund-details-circle {
    padding: 39px 25px 35px;
  }
  .fund-details-circle-1 {
    padding: 39px 25px 35px;
  }
  .fund-details-circle .col-md-6.col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }
  .fund-details-circle-1 .col-md-6.col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }
  .newfunddetailssec .col-12.col-md-8.col-lg-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .newfunddetailssec
    .col-12.col-md-4.col-lg-4.align-self-end.d-none.d-sm-block {
    display: none !important;
  }
  .conneting-strings-sm img:first-child {
    left: 15px;
  }
  .conneting-strings-sm img:last-child {
    right: 15px;
  }
  .bankstrip-sec .pl-md-5,
  .px-md-5,
  .authstrip-sec .pl-md-5 {
    padding-left: 1.5rem !important;
  }
  .bankstrip-sec .pr-md-5,
  .px-md-5 {
    padding-right: 1rem !important;
  }
  .switch-imgsec {
    display: none !important;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .addmoney-title {
    float: none;
  }
  .counter .fund-value {
    font-size: 22px;
  }
  .counter .fund-label {
    font-size: 12px;
  }
  .counter .fund-smallvalue {
    font-size: 18px;
  }
  .counter-1 .fund-value {
    font-size: 22px;
  }
  .counter-1 .fund-label {
    font-size: 12px;
  }
  .counter-1 .fund-smallvalue {
    font-size: 18px;
  }
  .parametertitle {
    font-size: 14px;
  }
  .fund-details-circle {
    padding: 74px 25px 35px;
  }
  .fund-details-circle-1 {
    padding: 74px 25px 35px;
  }
  .amt-sec {
    padding: 80px 0px 40px 10px;
  }
  .switch-imgsec img {
    width: 225px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .yg_investTabs ul li:first-child {
    margin-right: 10px;
  }
  .smalltitle-img {
    padding: 7px 4px;
    margin-right: 4px;
  }
  .smalltitle-img h2 {
    font-size: 11px;
  }
  .folio-tab-sec {
    padding-left: 0px;
  }
  .folio-tab-sec .btn-sm {
    padding: 0.25rem 0.2rem;
    font-size: 0.775rem;
  }
}

.exit-help-tip {
  display: inline-block;
  margin-left: 0;
  vertical-align: middle;
  cursor: pointer;
  margin-top: -3px;
  position: relative;
}
.exitInfoHoverText {
  display: none;
  text-align: left;
  background-color: #333;
  padding: 10px 15px;
  width: 205px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 1em 2em -0.5em rgb(0 0 0 / 35%);
  left: -100px;
  top: 25px;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  z-index: 3;
  text-transform: none;
  margin-bottom: 0;
}
.exitInfoHoverText:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #333;
  left: 100px;
  top: -12px;
}
.exitInfoHoverText:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}
.exit-help-tip:hover .exitInfoHoverText {
  display: block;
  transform-origin: 100% 0;
  animation: fadeIn 0.3s ease-in-out;
}

.sip-help-tip {
  display: inline-block;
  margin-left: 0;
  vertical-align: middle;
  cursor: pointer;
}
.sipInfoHoverText {
  display: none;
  text-align: left;
  background-color: #333;
  padding: 10px 15px;
  width: 205px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 1em 2em -0.5em rgb(0 0 0 / 35%);
  left: -8px;
  top: 25px;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  z-index: 3;
  text-transform: none;
  margin-bottom: 0;
}
.sipInfoHoverText:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #333;
  left: 10px;
  top: -12px;
}
.sipInfoHoverText:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}
.sip-help-tip:hover .sipInfoHoverText {
  display: block;
  transform-origin: 100% 0;
  animation: fadeIn 0.3s ease-in-out;
}

.euin-help-tip {
  display: inline-block;
  margin-left: 0;
  vertical-align: middle;
  cursor: pointer;
}
.euinInfoHoverText {
  display: none;
  text-align: left;
  background-color: #333;
  padding: 10px 15px;
  width: 205px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 1em 2em -0.5em rgb(0 0 0 / 35%);
  left: -20px;
  top: 25px;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  z-index: 3;
  text-transform: none;
  margin-bottom: 0;
}
.euinInfoHoverText:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #333;
  right: 15px;
  top: -12px;
}
.euinInfoHoverText:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}
.euin-help-tip:hover .euinInfoHoverText {
  display: block;
  transform-origin: 100% 0;
  animation: fadeIn 0.3s ease-in-out;
}

.booster-help-tip {
  display: inline-block;
  margin-left: 0;
  vertical-align: middle;
  cursor: pointer;
}
.boosterInfoHoverText {
  display: none;
  text-align: left;
  background-color: #333;
  padding: 10px 15px;
  width: 390px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 1em 2em -0.5em rgb(0 0 0 / 35%);
  left: 0px;
  top: 15px;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  z-index: 3;
  text-transform: none;
  margin-bottom: 0;
}
.boosterInfoHoverText:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #333;
  left: 5px;
  top: -12px;
}
.boosterInfoHoverText:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}
.booster-help-tip:hover .boosterInfoHoverText {
  display: block;
  transform-origin: 100% 0;
  animation: fadeIn 0.3s ease-in-out;
}

.textEllipsis {
  text-overflow: ellipsis;
}
.fullNameToolTip {
  position: relative;
}
.fullNameHoverText {
  display: none;
  text-align: left;
  background-color: #333;
  padding: 10px 15px;
  width: auto;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 1em 2em -0.5em rgb(0 0 0 / 35%);
  left: 0px;
  top: 100%;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  z-index: 3;
  text-transform: none;
  margin-bottom: 0;
}
.fullNameHoverText:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #333;
  left: 5px;
  top: -12px;
}
.fullNameHoverText:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}
.fullNameToolTip:hover .fullNameHoverText {
  display: block;
  transform-origin: 100% 0;
  animation: fadeIn 0.3s ease-in-out;
}
/* Main Wrapper Ends*/

/*Downloads Starts*/
.DownlaodView {
  min-height: 180px;
  border: 1px solid #eeeeee;
  background: #fff;
  padding: 5px 5px 15px 5px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}

.DownloadData {
  width: 84px;
  height: 50px;
  background: rgba(74, 78, 87, 0.1);
  border-radius: 4px 27px 27px 4px;
  padding: 5px 10px;
  padding-right: 2px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.DownlaodView:hover .DownloadData {
  background: rgba(277, 77, 77, 0.2);
}
.DownlaodView:hover {
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.07);
}

.DownloadData p {
  font-size: 12px;
  font-family: 'Roboto';
font-weight: 400;
  margin: 0px;
  padding-top: 0px;
  text-transform: uppercase;
  color: #6a738a;
  line-height: 17px;
}

.DownloadData p span {
  font-size: 18px;
  display: block;
  font-family: 'Roboto';
  font-weight: 700;
}

.DownlaodView .fileName_pdf {
  font-size: 15px;
  color: #6a738a;
  padding: 0px 10px;
  margin: 15px 0px;
  font-family: 'Roboto';
font-weight: 400;
}

.pdf_icon {
  background: url("/assets/images/download/pdf.png");
  width: 32px;
  height: 36px;
  display: block;
  cursor: pointer;
  margin-left: 10px;
  background-size: contain;
}

.xls_icon {
  background: url("/assets/images/download/xls.png");
}

.edit_icon {
  background: url("/assets/images/download/Edit.svg");
  background-size: cover;
  width: 40px;
}

.sameline {
  display: inline-flex;
}

.download_option {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgb(0, 0, 0, 0.57);
}

.downLoad_optionUL {
  list-style: none;
  padding: 0px;
  margin: 0;
  width: 90%;
  position: relative;
  text-align: center;
  background: #ffffff;
  margin: 0px auto;
  margin-top: 20%;
  border-radius: 10px;
}

.downLoad_optionUL li {
  display: inline-table;
  padding: 10px 11px;
  padding-top: 5px;
}

.downLoad_optionUL li a,
.downLoad_optionUL li a:hover {
  text-decoration: none;
  font-family: 'Roboto';
font-weight: 500;
  font-size: 8px;
  color: #6a738a;
}

.icon_sec_down {
  width: 23px;
  height: 18px;
  margin: 0px auto;
  display: block;
}

.icon_sec_down img {
  width: 100%;
}

.downLoad_optionUL .close_icon {
  cursor: pointer;
  width: 15px;
  display: block;
  position: absolute;
  right: 0;
  top: -7px;
  height: 15px;
  background: url("/assets/images/download/close.svg") no-repeat center center;
}
/*Downloads Ends*/

/*Date Start*/
.icon_calender {
  width: 15px;
  height: 15px;
  background: url("/assets/images/date/calender.svg") no-repeat;
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: 20px;
}
.datePicker .react-datepicker-wrapper {
  width: 100% !important;
  display: block !important;
}
.datePicker.calendar-inside {
  background-color: #fff;
  border-bottom: 1px solid silver;
}
.datePicker.calendar-inside .icon_calender {
  bottom: 10px;
  pointer-events: none;
}
.datePicker.calendar-inside .react-datepicker-wrapper input {
  margin-bottom: 0px;
  border-bottom: none;
}
.datePicker .react-datepicker-wrapper button {
  width: 100%;
  box-shadow: none;
  border: 0px;
  text-align: left;
  height: 38px;
  border-bottom: 1px solid #bfc3ce;
}

.react-datepicker__input-container > input[type="text"] {
  width: 100%;
}

/*Date Ends*/

/*Total Expense Ratio Starts */
.table_expense_ratio tr th {
  vertical-align: middle;
}
.table_pass tbody {
  background-color: #fff;
}

.table_pass tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table_pass tr th {
  background: #272e59;
  padding: 15px 20px;
  color: #fff !important;
  font-family: 'Roboto';
font-weight: 400;
  font-size: 15px;
}
.hisnav-sec .table_pass tr th:nth-child(2),
.hisnav-sec .table_pass tr td:nth-child(2),
.latnav-sec .table_pass tr th:nth-child(2),
.latnav-sec .table_pass tr td:nth-child(2), 
.latdiv-sec .table_pass tr th:nth-child(2),
.latdiv-sec .table_pass tr td:nth-child(2){    
  width: 45%;
  white-space: normal;}
  .totalexpensesec .table_pass > thead > tr:first-child > th:first-child,
  .totalexpensesec .table_pass > tbody > tr > td:first-child{white-space: nowrap;}
  .totalexpensesec .table_pass tr th:nth-child(2),
  .totalexpensesec .table_pass tr td:nth-child(2){width: 60%;}
  .totalexpensesec .table_pass tr th,
.totalexpensesec .table_pass tr td{    
  /* width: 70%; */
  white-space: normal;
  padding: 15px 10px !important;}

.table_pass tr th,
.table_pass tr td {
  padding: 15px 20px !important;
  border-top: 0px solid #fff;
}

.table_pass tr td {
  color: #1a294e;
  font-size: 14px;
  font-family: 'Roboto';
font-weight: 400;
  white-space: nowrap;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6 !important;
}
/*Total Expense Ratio Ends */

/* transform start */
/* toggle check */

.width-auto {
  width: auto !important;
}

.custom-checkbox.big-custom-checkbox {
  width: 200px;
  height: 38px;
}
.custom-checkbox.normal-custom-checkbox {
  width: 150px;
  height: 30px;
  margin: 0 auto;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox input + label {
  height: 100%;
  width: 100%;
}

.custom-checkbox input + label > .status-switch {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  color: #6a738a;
  transition: all 0.5s ease;
  padding: 3px;
  border-radius: 3px;
  border: 1px solid #e0e4ec;
  font-size: 12px;
  font-family: 'Roboto';
font-weight: 400;
  font-weight: 400;
}

.custom-checkbox.big-custom-checkbox input + label > .status-switch {
  font-size: 11px;
}

.custom-checkbox input + label > .status-switch:before,
.custom-checkbox input + label > .status-switch:after {
  border-radius: 2px;
  height: calc(100% - 6px);
  width: calc(50% - 3px);
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  transition: all 0.3s ease;
}

.custom-checkbox input + label > .status-switch:before {
  background-color: #21295a;
  color: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 6px 6px rgba(0, 0, 0, 0.15);
  left: 3px;
  z-index: 0;
  content: attr(data-unchecked);
}

.custom-checkbox input + label > .status-switch:after {
  right: 0;
  content: attr(data-checked);
}

.custom-checkbox input:checked + label > .status-switch {
  /*  background-color: #40c253;*/
}

.custom-checkbox input:checked + label > .status-switch:after {
  left: 0;
  content: attr(data-unchecked);
}

.custom-checkbox input:checked + label > .status-switch:before {
  color: #fff;
  left: 50%;
  content: attr(data-checked);
}

/* custom Redio */
.input-container {
  position: relative;
  display: inline-block;
  padding: 5px;
  width: auto;
  margin: 0 auto;
}

.input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
  font-family: 'Roboto';
font-weight: 400;
}

.input-container .radio-tile {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid #cccccc;
  padding: 5px;
  -webkit-transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  border-radius: 3px;
}

.radio-inputsec .input-container .radio-tile {
  padding: 5px 10px;
  border-radius: 3px;
}

.input-container .radio-tile-label {
  text-align: center;
  font-size: 12px;
  color: #cccccc;
  margin-bottom: 0;
  font-family: 'Roboto';
font-weight: 400;
  font-weight: 400;
  text-transform: capitalize;
  line-height: normal;
  padding-left: 0px !important;
}

.radio-inputsec .input-container .radio-tile-label {
  font-size: 13px;
}

.input-container .radio-tile-label .rupeesign {
  color: #cccccc;
  padding-left: 2px;
}

.input-container .radio-button:checked + .radio-tile {
  background-color: #fff;
  border: 1px solid #21295a;
  color: white;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.radio-inputsec .input-container .radio-button:checked + .radio-tile {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
}

.input-container .radio-button:checked + .radio-tile .radio-tile-label {
  color: #21295a;
  border-color: #21295a;
  background-color: #fff;
}

.input-container > .radio-tile.checked {
  color: #444;
  text-decoration: none;
  border: 1px solid #bbb;
  background-color: #ededed;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5),
    rgba(221, 221, 221, 1)
  );
  transition: background-color 0.2s ease-out;
  background-clip: padding-box;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgb(0 0 0 / 30%), 0 2px 2px -1px rgb(0 0 0 / 50%),
    0 1px 0 rgb(255 255 255 / 30%) inset;
  text-shadow: 0 1px 0 rgb(255 255 255 / 90%);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.input-container > .radio-tile.checked .radio-tile-label {
  color: #444;
  border-color: #21295a;
  background-color: transparent;
  font-family: 'Roboto';
  font-weight: 700;
}

.quick-amt-sec .input-container .radio-tile {
  padding: 5px 8px;
}

.quick-amt-sec .input-container .radio-button:checked + .radio-tile {
  color: #444;
  text-decoration: none;
  border: 1px solid #bbb;
  background-color: #ededed;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5),
    rgba(221, 221, 221, 1)
  );
  transition: background-color 0.2s ease-out;
  background-clip: padding-box;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgb(0 0 0 / 30%), 0 2px 2px -1px rgb(0 0 0 / 50%),
    0 1px 0 rgb(255 255 255 / 30%) inset;
  text-shadow: 0 1px 0 rgb(255 255 255 / 90%);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
.quick-amt-sec
  .input-container
  .radio-button:checked
  + .radio-tile
  .radio-tile-label {
  color: #444;
  border-color: #21295a;
  background-color: transparent;
  font-family: 'Roboto';
  font-weight: 700;
}

/* button tr */

.btn-addtocart {
  background-color: #fff;
  color: #4e5b71;
  border: 2px solid #a7afbd;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  outline: 0;
  cursor: pointer;
  display: block;
  text-align: left;
  padding: 10px 70px 10px 19px;
  width: 100%;
  border-radius: 0.25rem;
  margin: 0 auto;
}

.btn-addtocart:hover {
  background-color: #e63946;
  color: #fff;
  border: 2px solid #e63946;
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
  text-decoration: none;
}

.btn-addtocart:hover .iconimg {
  border: 2px solid #ed1c24;
}

.btn-red {
  background-color: #e63946;
  color: #fff;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 700;
  font-weight: 600;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: block;
  text-align: center;
  width: auto;
  border-radius: 0.25rem;
}

.btn-red:hover,
.btn-red:active,
.btn-red:focus {
  background-color: var(--accentBlue);
  color: #fff;
  /* -webkit-box-shadow: 0 14px 24px 0 rgba(0, 0, 0, 0.26);
    box-shadow: 0 14px 24px 0 rgba(0, 0, 0, 0.26); */
  text-decoration: none;
}

.btn-lg {
  padding: 13px 20px;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: normal;
}

.iconimg {
  background-color: #fff;
  border-radius: 0.25rem;
  display: inline-block;
  padding: 11px;
  line-height: 0;
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: 0px;
  border: 2px solid #a7afbd;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.secondarybts {
  border: 1px solid #cf0e21;
  color: #cf0e21;
  background-color: #fff;
}
.morecontent span {
  display: none;
}

.Readmorebtn {
  vertical-align: top;
}

/* end button tr */
@media (max-width: 767px) {
  .iconimg {
    right: 15px;
  }
  .radio-inputsec .input-container .radio-tile {
    padding: 5px 8px;
  }
  .btn-lg {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .btn-red {
    font-size: 10px;
  }
}

/* transform end */
