.bg_detail {
    width: 100%;
    min-height: 280px;
    padding-bottom: 40px;
    background: url("/assets/images/listing/mfDetailbg.svg") no-repeat;
    background-size: cover;
    background-position: 100% 100%;
  }
  
  .bg_pms {
    background: url("/assets/images/listing/bg_pms.svg")
      rgba(87, 18, 120, 0.95) no-repeat;
    background-size: cover;
    background-position: 100% 100%;
    position: relative;
  }
  
  /* audio msg */
  .paddingmgAudio {
    padding-top: 50px;
  }
  
  .nameimg {
    font-size: 16px;
    margin: 6px 0px;
  
    color: #444444;
    margin-bottom: 0px;
    font-family: 'Roboto';
font-weight: 400;
  }
  
  .nameimgPos {
    font-size: 14px;
    margin: 0px;
    color: #707070;
  }
  
  .musicktitle {
    color: #272e59;
    font-size: 28px;
    font-family: 'Roboto';
  font-weight: 700;
    margin: 30px 0px;
    position: relative;
  }
  
  /* performance page */
  .form_per,
  .colwhite {
    background: #f7e4e5;
    position: relative;
    height: 79px;
    border-right: 1px solid #ff9ba1;
  }
  #Performance .css-1s2u09g-control,  #Performance .txtSelect .css-1pahdxg-control{background-color: transparent;border: 0;
    border-bottom: 1px solid #1a294e; border-radius: 0;box-shadow: none;}
    #Performance .txtSelect .css-1pahdxg-control:hover{outline: none; box-shadow: none;}
  #Performance .css-qc6sy-singleValue{font-size: 16px; font-family: 'Roboto';
  font-weight: 700;}
  
  .form_per:after {
    content: "";
    transition: all 0.3s ease;
    display: block;
    position: absolute;
    top: 30px;
    right: -7px;
    z-index: 99;
    margin: auto;
    width: 15px;
    height: 15px;
    background: #f7e4e5;
    border-right: 1px solid #ff9ba1;
    border-bottom: 1px solid #ff9ba1;
    -moz-transform: rotate(314deg);
    -webkit-transform: rotate(314deg);
  }
  
  .colwhite {
    background: #fff;
    border: 0px;
    padding: 0px 5px;
  }
  
  .col-rightp {
    color: #1a294e;
    font-size: 14px;
    padding-top: 20px;
    text-align: center;
    font-family: 'Roboto';
font-weight: 500;
  }
  
  .col-rightp span {
    font-family: 'Roboto';
  font-weight: 700;
  }
  
  .col-rightp span:first-child {
    font-size: 21px;
  }
  
  .outperChertpm {
    font-size: 15px;
    padding-top: 3px;
    margin-bottom: 5px;
    margin-bottom: 0px;
    line-height: 17px;
  }
  
  .desc_chart_in {
    font-size: 16px;
    font-family: 'Roboto';
font-weight: 500;
    color: #1a294e;
    text-align: center;
    padding-top: 25px;
  }
  
  .desc_chart_in span {
    font-weight: 700;
  }
  
  .topoutperChertpm {
    padding-top: 10px !important;
  }
  
  .coldrop1,
  .coldrop2,
  .coldrop3 {
    float: left;
    box-sizing: border-box;
    vertical-align: middle;
    padding-top: 16px !important;
  }
  
  .coldrop1 {
    width: 20%;
  }
  
  .coldrop2 {
    width: 50%;
    padding: 0px 10px;
  }
  
  .coldrop3 {
    width: 30%;
  }
  
  .coldrop2 .colinline,
  .coldrop3 .colinline {
    display: block;
    float: left;
    width: 50%;
  }
  
  .coldrop2 .col_label,
  .coldrop3 .col_label {
    font-size: 16px;
    font-family: 'Roboto';
font-weight: 500;
    color: #1a294e;
    width: 40%;
    padding-top: 12px;
    padding-right: 10px;
    text-align: right;
  }
  
  .col_label2 {
    width: 22% !important;
  }
  
  .colf {
    width: 60%;
  }
  
  .colf .inputtext {
    color: #1a294e;
    border: 0px;
    background: transparent;
    min-height: 38px;
    font-size: 17px;
    font-family: 'Roboto';
  font-weight: 700;
    border-bottom: 1px solid #1a294e;
  }
  
  .colf2 {
    width: 70% !important;
  }
  
  .colf input::placeholder {
    color: #1a294e;
  }
  
  .bottomDrop .css-yk16xz-control,
  .bottomDrop .css-2b097c-container:focus {
    border: 0px;
    border-bottom: 1px solid #1a294e;
    background: transparent;
    border-radius: 0px;
  }
  
  .bottomDrop .css-1okebmr-indicatorSeparator {
    width: 0px;
  }
  
  .bottomDrop .css-1uccc91-singleValue,
  .bottomDrop .css-1wa3eu0-placeholder {
    font-size: 18px;
    font-family: 'Roboto';
  font-weight: 700;
    color: #1a294e;
  }
  
  .bottomDrop .css-6q0nyr-Svg {
    color: #1a294e;
  }
  
  /* periodic return */
  
  .paraperiodic {
    text-align: center !important;
  }
  
  .headingmrg15 {
    margin-bottom: 20px;
  }
  
  .responsiveTable {
    width: 100%;
    display: block;
  }
  
  .tableHead {
    display: block;
    background: #323b6d;
    width: 100%;
    position: relative;
  }
  
  .colHr {
    width: 19.5%;
  
    float: left;
    font-size: 15px;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-family: 'Roboto';
font-weight: 500;
    border-left: 1px solid #878e9f;
  }
  
  .coltitle {
    width: 22%;
    display: list-item;
  }
  
  .colHr:first-child {
    border-color: transparent;
  }
  
  .colHr h4 {
    margin: 0px;
    font-size: 16px;
    color: #fff;
    font-family: 'Roboto';
font-weight: 500;
    text-align: center;
    padding: 9px 5px;
    background: transparent;
    border-bottom: 1px solid #878e9f;
  }
  
  .colHr .colhr_d40,
  .colHr .colhr_d80 {
    float: left;
    box-sizing: border-box;
    padding: 4px 5px;
  }
  
  .colHr .colhr_d40 {
    width: 40%;
  }
  
  .colHr .colhr_d80 {
    width: 60%;
    border-left: 1px solid #878e9f;
  }
  
  .tableBody {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 10px 4px;
    color: #444;
    font-family: 'Roboto';
font-weight: 400;
    transition: all 0.3s ease;
  }
  
  .tableBody:hover {
    background-color: #fffae9;
    color: #444444;
    font-family: 'Roboto';
font-weight: 500;
  }
  
  .tableBody .colHr .colhr_d80,
  .tableBody .colHr {
    border: 0px;
    color: inherit;
    font: inherit;
    font-size: 14px;
    transition: all 0.3s ease;
    display: block;
  }
  
  .tableBody .coltitle {
    padding-left: 15px;
  }
  
  .tableBody .colHr .colhr_d80,
  .tableBody .colHr .colhr_d40 {
    text-align: right;
  }
  
  .date_ince {
    color: #6a738a;
    font-family: 'Roboto';
font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
  }
  
  /* end */
  
  /* why invest */
  .col_whyInvest {
    min-height: 201px;
  
    border: 1px solid #aab1c4;
    position: relative;
  
    transition: background-color 0.5s ease;
  
    padding: 40px 65px;
    padding-top: 20px;
    padding-bottom: 10px;
    background-color: #fff;
  }
  
  .col_whyInvest:hover {
    background: #323b6d;
    color: #ffffff;
  }
  
  .col_whyInvest:nth-child(1),
  .col_whyInvest:nth-child(3) {
    border-right: 1px solid transparent;
  }
  
  .col_whyInvest:nth-child(1),
  .col_whyInvest:nth-child(2) {
    border-bottom: 1px solid transparent;
  }
  
  .why_circle {
    width: 110px;
    height: 110px;
    background: #fff;
    border-radius: 100px;
    position: absolute;
    padding: 20px;
    text-align: center;
    top: 30px;
  }
  
  .why_circle img {
    max-width: 100%;
    display: block;
    margin: auto;
  }
  
  .why_left {
    left: -55px;
  }
  
  .why_right {
    right: -55px;
  }
  
  .col_whyInvest h6 {
    color: #1a294e;
    font-size: 14px;
    font-family: 'Roboto';
font-weight: 500;
    margin: 10px 0px;
  }
  
  .col_whyInvest:hover h6,
  .col_whyInvest:hover p {
    color: #fff;
  }
  
  .col_whyInvest p {
    font-family: 'Roboto';
font-weight: 400;
    color: #6a738a;
    font-size: 13px;
    margin-bottom: 5px;
  }
  
  .all_inline {
    margin-bottom: 1px;
    position: relative;
    padding-left: 10px;
  }
  
  .all_inline span {
    width: 5px;
    height: 5px;
    top: 10px;
    vertical-align: top;
    left: 0;
    display: inline-block;
    background: #ffc107;
    border-radius: 100px;
    position: absolute;
  }
  
  /* passive detail */
  
  .passfName {
    color: #fff;
    font-size: 32px;
    margin: 0px;
    font-family: 'Roboto';
  font-weight: 700;
  }
  
  .disclaimer_pas {
    color: #6a738a;
    font-size: 12px;
    font-family: 'Roboto';
font-weight: 400;
  }
  
  .table_beckMarks tr th,
  .table_beckMarks tr td {
    padding: 10px 20px !important;
    font-size: 14px;
    color: #1a294e;
    font-family: 'Roboto';
font-weight: 500;
    border-top: 0px !important;
  }
  
  .table_beckMarks tr th {
    background: #323b6d;
    color: #fff;
  }
  
  .table_beckMarks {
    margin-bottom: 15px;
  }
  
  .table_beckMarks .table {
    margin-bottom: 0px;
  }
  
  .table_beckMarks tbody tr:hover {
    background: #fffae9;
  }
  
  /* pms *detail*/
  .inline_listing {
    display: inline-table;
    float: none;
  }
  
  .img_philosophy {
    margin: auto;
    display: block;
    max-width: 100%;
    margin-top: 20%;
  }
  
  .checkMargin {
    margin-top: 35px;
    margin-bottom: 10px;
  }
  .utbutton_left {
    margin-top: 20px;
  }
  .innerFixedTop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease;
    z-index: 9999;
  }
  .innerFixedTop .innerNav {
    margin-top: 0px !important;
    z-index: 9999;
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    /* .coldrop1, .coldrop2, .coldrop3{
          float: none;
      } */
    .form_per {
      height: auto;
      padding: 0px 30px 15px 30px;
    }
    .coldrop1 {
      width: 30%;
    }
    .coldrop2 {
      width: 45%;
      padding: 0px;
      padding-left: 1%;
    }
    .coldrop2 .colinline {
      width: 60%;
    }
    .coldrop2 .col_label {
      text-align: left;
      width: 35%;
      padding-right: 0px;
    }
    .col_label2 {
      width: 25% !important;
    }
    .colf2 {
      width: 75% !important;
    }
    .coldrop3 {
      width: 20%;
    }
    .coldrop3 .col_label {
      text-align: left;
      /* width: 25%; */
      padding-right: 0px;
    }
    .colf .inputtext {
      width: 100%;
    }
    .col-rightp {
      font-size: 17px;
    }
    .brder_top {
      padding-top: 20px;
    }
    .col_whyInvest {
      height: auto;
    }
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    .musicktitle {
      padding: 0px 12px;
      font-size: 22px;
    }
    .coldrop1,
    .coldrop2,
    .coldrop3 {
      float: none;
    }
    .form_per {
      height: auto;
      padding: 0px 30px 15px 30px;
    }
    .coldrop1 {
      width: 100%;
    }
    .coldrop2 {
      width: 100%;
      padding: 0px;
    }
    .coldrop2 .col_label {
      text-align: left;
      width: 35%;
      padding-right: 0px;
    }
    .coldrop3 {
      width: 100%;
    }
    .coldrop3 .col_label {
      text-align: left;
      width: 38%;
      padding-right: 0px;
    }
    .col_whyInvest {
      height: auto;
    }
    .colManager .imgCircle {
      position: static;
    }
    .nameLeftPadd {
      padding-left: 0px;
    }
    .col_whyInvest:nth-child(1),
    .col_whyInvest:nth-child(3) {
      border-right: 1px solid #aab1c4;
    }
    .checkMargin {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .img_philosophy {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .responsiveTable {
      overflow-y: auto;
    }
    .mob_th{
      width: 800px;
    }
    .mob_td{
      width: 800px;
    }
  }
  